import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import LightboxImageControls from './LightboxImageControls'
import LightboxVideoControls from './LightboxVideoControls'

const LightboxMainFoot = ({ media, index, setIndex, videoRef, isPlaying, handleTogglePlaying, progress, setProgress }) => {
	const fileType = media?.fileType
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			gap: 1,
			paddingTop: 1,
			paddingLeft: 1.5,
			paddingRight: 1.5,
			paddingBottom: 1,
			height: 64,
			[theme.breakpoints.down('md')]: {
				gap: 0.25,
				justifyContent: 'space-between',
				paddingTop: 0.5,
				paddingLeft: 0.5,
				paddingRight: 0.5,
				paddingBottom: 0.5,
				width: '100%',
				height: 52,
				backgroundColor: theme.palette.background.darkest,
			},
		},
	}
	
  let controls = null
  if (fileType === 'image') controls = <LightboxImageControls media={media} index={index} setIndex={setIndex} />
  else if (fileType === 'video') controls = <LightboxVideoControls videoRef={videoRef} isPlaying={isPlaying} handleTogglePlaying={handleTogglePlaying} progress={progress} setProgress={setProgress} />

  // return <Box sx={styles.wrapper}>{controls}</Box>
	if (fileType === 'image') return <Box sx={styles.wrapper}>{controls}</Box>
	else if (fileType === 'video') return null
}

export default LightboxMainFoot