import { createSlice } from '@reduxjs/toolkit'

const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {
    setMediaRequested: (state) => {
      state.isLoading = true
      state.error = null
    },
    setMediaSucceeded: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },
    setMediaFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const { setMediaRequested, setMediaSucceeded, setMediaFailed } = mediaSlice.actions
export default mediaSlice.reducer