import { useSelector } from 'react-redux'
import { Box, Typography, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { theme } from '../../themes/theme'
import Nav from './Nav/Nav'
import Main from './Main'
import Container from './Container'
import { Link } from 'react-router-dom'

const PrivatePage = ({ children }) => {
  const user = useSelector(state => state.rootReducer.user.data)
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			gap: '24px',
			width: '100%',
			padding: 3,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '16px',
			width: '100%',
			padding: 6,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 3,
			backgroundColor: theme.palette.background.default,
		},
	}

	if (!user) return (
		<Container>
			<Box sx={styles.wrapper}>
				<Box sx={styles.content}>
					<FontAwesomeIcon
						size='2x'
						style={{ color: theme.palette.text.disabled, marginBottom: 8, }}
						icon={icon({ name: 'lock', family: 'sharp', style: 'solid', })}
					/>
					<Typography variant='subtitle1'>You'll need to create an account or sign in.</Typography>
					<Button variant='contained' color='secondary' size='large' component={Link} to='/' disableRipple>Go to login</Button>
				</Box>
			</Box>
		</Container>
	)
	
	return (
		<>
			<Nav />
			<Main>
				<Container>
					{children}
				</Container>
			</Main>
		</>
	)
}

export default PrivatePage