import { useState } from 'react'
import LandingLogin from './LandingLogin'
import LandingReset from './LandingReset'

const LandingOldUser = () => {
  const [mode, setMode] = useState('login')

  if (mode === 'login') return <LandingLogin setMode={setMode} />
  if (mode === 'reset') return <LandingReset setMode={setMode} />
}

export default LandingOldUser