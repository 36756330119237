import { Snackbar as MuiSnackbar, alpha, Button } from '@mui/material'

import { theme } from '../../themes/theme'

const Snackbar = ({ snackbar, setSnackbar }) => {
  const { open, message, type } = snackbar
	const styles = {
		snackbar: {
			'& .MuiSnackbarContent-message': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: 8,
				width: '100%',
			},
		},
		error: {
			'& .MuiSnackbarContent-root': {
				backgroundColor: '#d32f2f',
				color: theme.palette.error.contrastText,
			},
		},
		success: {
			'& .MuiSnackbarContent-root': {
				backgroundColor: '#43a047',
				color: theme.palette.success.contrastText,
			},
		},
		dismiss: {
			height: 32,
			padding: '0 12px',
			fontWeight: 500,
			color: theme.palette.text.inverse,
			'&:hover': {
				backgroundColor: alpha(theme.palette.common.white, 0.1),
				color: theme.palette.common.white,
			},
		},
	}
  let setStyles

  if (type === 'success') setStyles = styles.success
  if (type === 'error') setStyles = styles.error

  return (
    <MuiSnackbar
      sx={{ ...styles.snackbar, ...setStyles }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={5000}
      onClose={() => setSnackbar(prevState => ({ ...prevState, open: false }))}
      open={open}
      message={(
        <>
          {message}
          <Button
            sx={styles.dismiss}
            onClick={() => setSnackbar(prevState => ({ ...prevState, open: false }))}
            children='Dismiss'
						disableRipple
          />
        </>
      )}
    />
  )
}

export default Snackbar