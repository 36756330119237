import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LocationsListItemTrails from './LocationsListItemTrails'

const LocationsListItem = ({ location, focus, setFocus }) => {
	const { width } = useWindowDimensions()
	const isFocused = focus?.id === location?.id
	const styles = {
		wrapper: {
			width: '100%',
			padding: 2,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 4,
			overflow: 'hidden',
			transition: `background-color 0.2s ease`,
			'&.MuiAccordion-root:first-of-type': { borderRadius: '16px !important', },
			'&.MuiAccordion-root:last-of-type': { borderRadius: '16px !important', },
			'&:hover': { backgroundColor: theme.palette.background.paper, },
			'&.Mui-expanded': { backgroundColor: theme.palette.background.paper, },
			[theme.breakpoints.down('sm')]: {
				padding: 0,
			},
		},
		image: {
			width: width < 640 ? '100%' : '40%',
			aspectRatio: width < 640 ? '3 / 2' : '4 / 3',
			borderRadius: width < 640 ? 0 : 10,
			objectFit: 'cover',
		},
		content: {
			paddingTop: 2,
			[theme.breakpoints.down('sm')]: {
				padding: 2,
			},
		},
	}

	let devicesCount = 0

	location?.trails.forEach(trail => {
		devicesCount += trail.devices.length
	})

	const handleChange = () => !isFocused ? setFocus(location) : setFocus(null)

	return (
		<Accordion sx={styles.wrapper} expanded={isFocused} onClick={handleChange} square={false} disableGutters>
			<AccordionSummary sx={{ width: '100%', padding: 0, margin: 0, }}>
				<Stack direction={width < 640 ? 'column' : 'row'} gap={width < 640 ? 0 : 2}>
					<img style={styles.image} src={location?.coverImage} alt={location?.location_name} />
					<Stack sx={styles.content} justifyContent='space-between' gap={1}>
						<Stack gap={1}>
							<Typography variant='h3' children={location?.location_name} />
							<Stack>
								<Typography color={theme.palette.text.secondary} variant='body3' children={`${location?.address}, ${location?.city}, ${location?.state}`} />
								<Typography color={theme.palette.text.secondary} variant='body3' children={`Cameras online: ${location?.ops_time_start} - ${location?.ops_time_end}`} />
							</Stack>
						</Stack>
						<Typography variant='subtitle4' children={`${location?.trails.length} trails • ${devicesCount} cameras`} />
					</Stack>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<LocationsListItemTrails location={location} />
			</AccordionDetails>
		</Accordion>
	)
}
 
export default LocationsListItem