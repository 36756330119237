import bmx from '../assets/images/bmx.jpg'
import kayak from '../assets/images/kayak-raft.jpg'
import motocross from '../assets/images/motocross.jpg'
import mtb from '../assets/images/mtb.jpg'
import rally from '../assets/images/rally-auto.jpg'
import skateboard from '../assets/images/skateboard.jpg'
import ski from '../assets/images/ski.jpg'
import snowboard from '../assets/images/snowboard.jpg'
import surf from '../assets/images/surf.jpg'
import wakeboard from '../assets/images/wakeboard.jpg'

export const sportsList = [
  {name: 'MTB', img: mtb},
  {name: 'BMX', img: bmx},
  {name: 'Ski', img: ski},
  {name: 'Snowboard', img: snowboard},
  {name: 'Motocross', img: motocross},
  {name: 'Rally/Auto', img: rally},
  {name: 'Kayak/Raft', img: kayak},
  {name: 'Skateboard', img: skateboard},
  {name: 'Surf', img: surf},
  {name: 'Wakeboard', img: wakeboard},
]