import { collection, query, where, onSnapshot } from 'firebase/firestore'

import { db } from '../../firebase/initialize'
import { getFileType } from '../../utilities/getFileType'
import { setMediaRequested, setMediaSucceeded, setMediaFailed } from '../reducers/mediaSlice'
import { setInactiveMediaEmpty } from '../reducers/inactiveMediaSlice'
import { fetchInactiveMedia } from './inactiveMediaActions'

// Utility function to debounce calls
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

export const fetchMedia = user => async (dispatch, getState) => {
  dispatch(setMediaRequested())

  const userId = user?.id
  const locations = getState().rootReducer.locations.data
  
  // If no user is signed in, media data is null
  if (!userId) return dispatch(setMediaSucceeded(null))

  // If locations haven't loaded yet, retry in 250 milliseconds
  if (!locations) {
    debounce(() => dispatch(fetchMedia(user)), 250)()
    return
  }

  try {
    const mediaColRef = collection(db, 'media')
    const mediaQryRef = query(mediaColRef, where('user_id', '==', userId))

    onSnapshot(mediaQryRef, (mediaSnap) => {
      
      // If the user has no media, check if they have inactive media
      if (mediaSnap.empty) {
        dispatch(setMediaSucceeded(null))
        dispatch(fetchInactiveMedia(user))
        return
      }

      let mediaArr = []
      const locationMap = new Map(locations.map(location => [location.id, location]))

      mediaSnap.forEach(doc => {
        const id = doc.id
        const data = doc.data()
        const createdAt = data?.createdAt?.seconds ? new Date(data.createdAt.seconds * 1000) : null
        const isValidDate = createdAt instanceof Date && !isNaN(createdAt)
        const filePath = data?.path?.[0]
        const location = locationMap.get(data.location_id)
        const locationName = location?.location_name

        if (isValidDate) {
          const date = createdAt.toDateString()
          const fileType = getFileType(filePath)
          let mediaObj = { id, datetime: createdAt, date, fileType, ...data }

          mediaObj.locationName = locationName
          mediaObj.locationCity = location?.city
          mediaObj.locationState = location?.state

          if (!data?.hidden) mediaArr.push(mediaObj)
        }
      })

      dispatch(setMediaSucceeded(mediaArr))
      dispatch(setInactiveMediaEmpty())
    }, (error) => {
      console.error(error)
      dispatch(setMediaFailed(error.message))
    })
  } catch (error) {
    console.error(error)
    dispatch(setMediaFailed(error.message))
  }
}