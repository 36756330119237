import { useEffect } from 'react'
import { Box } from '@mui/material'
// import { Box, IconButton, Slider } from '@mui/material'

import { theme } from '../../themes/theme'

const LightboxVideoControls = ({ videoRef, isPlaying, handleTogglePlaying, progress, setProgress, }) => {
	// const playIcon = <svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 384 512'><path fill={theme.palette.text.inverse} d='M384 256L0 32V480L384 256z'/></svg>
	// const pauseIcon = <svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'><path fill={theme.palette.text.inverse} d='M128 64H0V448H128V64zm192 0H192V448H320V64z'/></svg>
	const styles = {
		wrapper: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			gap: 1,
			width: '100%',
			[theme.breakpoints.down('md')]: {
				paddingLeft: 1,
				paddingRight: 1,
			}
		},
		// button: {
		// 	height: '100%',
		// 	width: 48,
		// 	[theme.breakpoints.down('md')]: { width: 44, },
		// },
		slider: {
			flex: 1,
		},
	}

	useEffect(() => {
		const video = videoRef?.current
		if (!video) return

		const updateProgress = () => {
			const value = (video.currentTime / video.duration) * 100
			setProgress(value)
		}
		
		video.addEventListener('timeupdate', updateProgress)
		return () => video.removeEventListener('timeupdate', updateProgress)
	}, [videoRef, setProgress])

	useEffect(() => {
		const handleKeyDown = (event) => {
			const frameDuration = 1 / 30
			const video = videoRef.current
			if (!video) return

			switch (true) {
				// case event.key === ' ':
				// case event.key === 'Spacebar':
				// 	event.preventDefault()
				// 	handleTogglePlaying()
				// 	break
				case event.key === 'ArrowLeft' && !isPlaying:
					event.preventDefault()
					video.currentTime = Math.max(0, video.currentTime - frameDuration)
					break
				case event.key === 'ArrowRight' && !isPlaying:
					event.preventDefault()
					video.currentTime = Math.min(video.duration, video.currentTime + frameDuration)
					break
				default:
					break
			}
		}

		document.addEventListener('keydown', handleKeyDown)
		return () => document.removeEventListener('keydown', handleKeyDown)
	// eslint-disable-next-line
	}, [handleTogglePlaying, videoRef])

	// const handleSetProgress = (event, newValue) => {
	// 	const video = videoRef.current
	// 	const newTime = (newValue / 100) * video.duration
	// 	video.currentTime = newTime
	// }

	return (
		<Box sx={styles.wrapper}>
			{/* <IconButton sx={styles.button} color='black' onClick={handleTogglePlaying}>
				{isPlaying ? pauseIcon : playIcon}
			</IconButton>
			<Slider sx={styles.slider} size='small' color='secondary' value={progress} onChange={handleSetProgress} /> */}
		</Box>
	)
}
 
export default LightboxVideoControls