export const getMediaThumbnail = mediaItem => {
	const mediaPath = mediaItem?.path
	const mediaThumbnails = mediaItem?.path_thumbnails
	const fileType = mediaItem?.fileType

	if (!mediaItem || !mediaPath) return { path: null, fileType: null, }

	// Determine appropriate cover index
  let coverIndex = mediaItem?.cover_index
  const coverIndexIsValid = coverIndex !== undefined && coverIndex >= 0 && coverIndex < mediaPath?.length
  if (!coverIndexIsValid) coverIndex = Math.floor(mediaPath.length / 2)

	let path = fileType === 'video' ? mediaThumbnails?.[coverIndex] : mediaPath?.[coverIndex] // WHY????

	// Reduce size and quality
	if (path) path = path.replace(
		path.includes('rfid-pytest')
			? 'storage.googleapis.com/rfid-pytest.appspot.com'
			: 'storage.googleapis.com/hightag-dev.appspot.com',
		'ik.imagekit.io/hightag/tr:ar-4-3,h-720,q-50')

	return { path, coverIndex }
}