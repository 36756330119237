import { theme } from '../theme'

export const Card = {
  MuiCard: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        borderRadius: 8,
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        paddingTop: 12,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 12,
        borderBottom: `1px solid ${theme.palette.divider}`,
      })
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        paddingTop: 20,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 20,
      })
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        paddingTop: 0,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 20,
      })
    },
  },
}