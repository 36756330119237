export const decode = (message) => {
  const dictionary = {
    "auth/user-not-found": "There's no account with that email address.",
    "auth/too-many-requests": "Too many sign in attempts. Please try again in a few minutes.",
    "auth/user-disabled": "This account has been disabled.",
    "auth/user-token-expired": "Your session has expired. Please sign in again.",
    "auth/id-token-expired": "Your session has expired. Please sign in again.",
    "auth/email-already-exists": "An account with that email address already exists.",
    "auth/email-already-in-use": "An account with that email address already exists.",
    "auth/insufficient-permission": "You don't have permission to do that.",
    "auth/internal-error": "Something went wrong on our end. Please try again.",
    "auth/invalid-argument": "Looks like you entered something incorrectly. Please try again.",
    "auth/invalid-email": "You've entered an invalid email.",
    "auth/invalid-id-token": "Your session access token is invalid. Please sign in again.",
    "auth/invalid-password": "The password you've entered is invalid. Passwords must be at least 6 characters.",
    "auth/invalid-phone-number": "You've entered an invalid phone number.",
    "auth/phone-number-already-exists": "There is already an account with that phone number.",
    "auth/session-cookie-expired": "Your session cookie has expired. Please sign in again.",
    "auth/session-cookie-revoked": "Your session cookie has been revoked. Please sign in again.",
    "auth/uid-already-exists": "A user with that ID already exists.",
    "auth/wrong-password": "The password you've entered is incorrect.",
  }

  return dictionary[message] || message
}