import { Tooltip, IconButton, Button, Typography } from '@mui/material'

import { useWindowDimensions } from '../../utilities/getWindowDimensions'

const LightboxActionSmall = ({ icon, tooltip, label, action, color, disabled }) => {
	const { width } = useWindowDimensions()
	let buttonColor = width < 992 ? 'light' : color || 'light'

	return (
		<Tooltip title={tooltip || label} arrow>
			<span>
				<IconButton onClick={action} color={buttonColor} disabled={disabled}>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	)
}

const LightboxActionLarge = ({ icon, label, action, disabled }) => {
	const styles = {
		wrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 1,
			height: '100%',
			borderRadius: 2,
		},
		label: {
			fontSize: 12,
			lineHeight: 1,
		},
	}

	return (
		<Button variant='outlined' color='inherit' sx={styles.wrapper} onClick={action} disabled={disabled} disableRipple>
			{icon}
			<Typography sx={styles.label}>{label}</Typography>
		</Button>
	)
}

const LightboxAction = ({ icon, tooltip, label, action, color, disabled, largeOnMobile }) => {
  const { width } = useWindowDimensions()
	const onMobile = width < 992

	// If largeOnMobile is true and we're on mobile, render the large button
	let markup = <></>
	if (largeOnMobile && onMobile) {
		markup = (
			<LightboxActionLarge
				icon={icon}
				tooltip={tooltip}
				label={label}
				action={action}
				disabled={disabled}
			/>
		)
	} else {
		markup = (
			<LightboxActionSmall
				icon={icon}
				tooltip={tooltip}
				label={label}
				action={action}
				color={color}
				disabled={disabled}
			/>
		)
	}

  return markup
}
 
export default LightboxAction