import { theme } from '../theme'
import { alpha } from '@mui/material'

const defContainedShadow = `0px 4px 12px -4px rgba(0,0,0,0.14),
														0px 1px 2px -1px rgba(0,0,0,0.32),
														inset 0 6px 12px rgba(255,255,255,0.06),
														inset 0 1px 1px rgba(255,255,255,0.12) !important`

const hovContainedShadow = `0px 8px 24px -8px rgba(0,0,0,0.14),
										  			0px 3px 6px -3px rgba(0,0,0,0.32),
										  			inset 0 6px 12px rgba(255,255,255,0.06),
										  			inset 0 1px 1px rgba(255,255,255,0.12) !important`

const defOutlinedShadow =  `0px 4px 12px -2px rgba(0,0,0,0.04),
														0px 1px 3px -1px rgba(0,0,0,0.08) !important`

const hovOutlinedShadow =  `0px 8px 24px -6px rgba(0,0,0,0.08),
														0px 3px 6px -3px rgba(0,0,0,0.16) !important`

export const Button = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 7,
        height: 40,
        margin: 0,
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        borderRadius: 7,
        whiteSpace: 'nowrap',

        // Sizes

        ...(ownerState.size === 'small' && {
          gap: 6,
          height: 34,
          paddingTop: 0,
          paddingLeft: 14,
          paddingRight: 14,
          paddingBottom: 0,
          borderRadius: 6,
        }),
        ...(ownerState.size === 'large' && {
          gap: 8,
          height: 44,
          paddingTop: 0,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 0,
          borderRadius: 8,
        }),

        // Contained

        ...(ownerState.variant === 'contained' && {
					boxShadow: defContainedShadow,

          ...(ownerState.color === 'primary' && {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.text.inverse,
            '&:hover': {
							backgroundColor: theme.palette.primary.dark,
							boxShadow: hovContainedShadow,
            },
						'&:disabled': {
							backgroundColor: theme.palette.primary.dark,
							boxShadow: hovContainedShadow,
						},
          }),

          ...(ownerState.color === 'secondary' && {
            backgroundColor: theme.palette.background.darker,
            color: theme.palette.text.inverse,
            '&:hover': {
              backgroundColor: theme.palette.background.darkest,
							boxShadow: hovContainedShadow,
            },
          }),

          ...(ownerState.color === 'error' && {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.text.inverse,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
							boxShadow: hovContainedShadow,
            },
          }),

        }),
        
        // Outlined

        ...(ownerState.variant === 'outlined' && {
					boxShadow: defOutlinedShadow,

          ...(ownerState.color === 'inherit' && {
						backgroundColor: 'transparent',
						border: `1px solid ${theme.palette.divider}`,
            '&:hover': {
							backgroundColor: 'transparent',
							borderColor: alpha(theme.palette.neutrals[500], 0.65),
							boxShadow: hovOutlinedShadow,
            },
          }),

        }),

        // Text

        ...(ownerState.variant === 'text' && {
          
          ...(ownerState.color === 'primary' && {
            color: theme.palette.primary.dark,
            '&:hover': { backgroundColor: theme.palette.primary.light, },
          }),
          
          ...(ownerState.color === 'secondary' && {
            color: theme.palette.text.primary,
            '&:hover': {
							color: theme.palette.heading.primary,
							backgroundColor: theme.palette.background.shaded,
						},
					}),

					...(ownerState.color === 'error' && {
						color: theme.palette.primary.dark,
						'&:hover': {
						},
          }),

        }),
      }),
    },
  },
}