import { Box } from '@mui/material'

import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import { theme } from '../../themes/theme'
import LightboxActionClose from './LightboxActionClose'
import LightboxInfoTime from './LightboxInfoTime'
import LightboxActionCoverset from './LightboxActionCoverset'
// import LightboxActionFullscreen from './LightboxActionFullscreen'

const LightboxMainHead = ({ media, index, handleCloseModal }) => {
  const { width } = useWindowDimensions()
	const fileType = media?.fileType
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: 1.5,
			paddingRight: 1.5,
			height: 70,
			[theme.breakpoints.down('md')]: {
				position: 'fixed',
				top: 0,
				left: 0,
				bottom: 0,
				zIndex: 2,
				paddingTop: 0.25,
				width: '100%',
				backgroundColor: theme.palette.background.default,
			},
		},
		left: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		center: {
			position: 'absolute',
			left: 'calc(50vw - (200px / 2))',
			display: 'flex',
			justifyContent: 'center',
			width: 200,
		},
		right: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
	}

	const actionsMarkup =
		fileType !== 'video' &&
		width > 992 &&
		<>
			<LightboxActionCoverset media={media} index={index} />
			{/* <LightboxActionFullscreen media={media} index={index} /> */}
		</>

  return (
    <Box sx={styles.wrapper}>
			<Box sx={styles.left}>
				<LightboxActionClose handleCloseModal={handleCloseModal} />
			</Box>
			<Box sx={styles.center}>
				{width < 992 && <LightboxInfoTime media={media} />}
			</Box>
			<Box sx={styles.right}>
				{actionsMarkup}
			</Box>
    </Box>
  )
}

export default LightboxMainHead