import { FormControl, FormGroup, FormControlLabel, Checkbox, alpha, Typography } from '@mui/material'

import { sportsList } from '../../utilities/sportsList'
import { theme } from '../../themes/theme'

const styles = {
  label: {
    paddingLeft: 0.5,
    paddingRight: 0.5,
    paddingBottom: 0.5,
    color: theme.palette.text.secondary,
  },
  checkboxGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
    gap: 0.25,
    borderRadius: 2,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
    },
  },
  checkboxLabel: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    aspectRatio: '3 / 2',
    margin: 0,
    borderRadius: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      opacity: 0.9,
    },
    '& .MuiTypography-root': {
      marginLeft: -0.5,
      marginBottom: 1,
      fontSize: 16,
      lineHeight: 1.7,
      color: theme.palette.common.white,
    },
  },
  checkbox: {
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white,
    },
  },
}

const AccountProfileSports = ({ values, setValues }) => {
  const handleCheckbox = (e) => {
    let sports = values?.sports || []

    sports = sports.includes(e.target.name)
      ? sports.filter(x => x !== e.target.name)
      : [...sports, e.target.name]

    setValues(prevState => ({ ...prevState, sports, }))
  }

  return (
    <FormControl sx={{ gridColumn: 'span 6', }}>
      <Typography variant='overline' sx={styles.label}>My sports</Typography>
      <FormGroup sx={styles.checkboxGroup}>
        {sportsList.map((sport, index) => (
          <FormControlLabel
            key={index}
            label={sport.name}
            sx={{
              ...styles.checkboxLabel,
              backgroundImage:
                `linear-gradient(
                  ${alpha(theme.palette.common.black, 0.0)} 50%,
                  ${alpha(theme.palette.common.black, 0.6)}
                ), url(${sport.img})`,
            }}
            control={
              <Checkbox
                name={sport.name}
                sx={styles.checkbox}
                onChange={handleCheckbox}
                checked={!!values?.sports?.includes(sport.name)}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default AccountProfileSports