import { collection, getDocs } from 'firebase/firestore'
import { setLocationsRequested, setLocationsSucceeded, setLocationsFailed } from '../reducers/locationsSlice'
import { db } from '../../firebase/initialize'

export const fetchLocations = () => async (dispatch) => {
  dispatch(setLocationsRequested())

  try {
    const trailsDocs = await getDocs(collection(db, 'trails'))
    const trails = trailsDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    const locationsColRef = collection(db, 'locations')

    const locationsSnapshot = await getDocs(locationsColRef)

    let locationsArr = []

    locationsSnapshot.forEach((doc) => {
      const id = doc.id
      const data = doc.data()
      let locationObj = { id, ...data, trails: [] }

      const locationTrails = trails.filter(trail => trail.location_id === id)

      locationTrails.forEach(trail => {
        if (!trail?.hidden) locationObj.trails.push(trail)
      })

      if (!data?.hidden) locationsArr.push(locationObj)
    })

    dispatch(setLocationsSucceeded(locationsArr))
  } catch (error) {
    console.error('Firestore error:', error)
    dispatch(setLocationsFailed(error.message))
  }
}