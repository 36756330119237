import { Box, Typography, Chip } from '@mui/material'
import { useSelector } from 'react-redux'

import { theme } from '../../themes/theme'

const SubscriptionDetails = () => {
	const user = useSelector(state => state.rootReducer.user.data)
	const activationType = user?.activation_type
	const activationStatus = user?.activation_status
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: 1,
			padding: 2,
			width: '100%',
			backgroundColor: theme.palette.background.shaded,
			borderRadius: 2,
		},
		status: { '& span': { fontSize: 10.5, }, },
	}

	return (
		<Box sx={styles.wrapper}>
			<Typography variant='button1' children={activationType} />
			<Chip size='small' color='secondary' sx={styles.status} label={activationStatus} />
		</Box>
	)
}
 
export default SubscriptionDetails