const fileTypes = {
	'.jpg': 'image',
	'.jpeg': 'image',
	'.png': 'image',
	'.mp4': 'video',
	'.mov': 'video',
}

export const getFileType = path => {
	const extension = path?.slice(path?.lastIndexOf('.'))?.toLowerCase()

	if (!fileTypes?.[extension]) console.error(`Cannot determine file type of path: ${path}`)

	return fileTypes[extension] || 'unknown'
}