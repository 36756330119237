import { theme } from '../theme'

export const IconButton = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        width: 40,
        height: 40,
        borderRadius: 7,

        // Sizes
        ...(ownerState.size === 'small' && {
          width: 32,
          height: 32,
        }),

        // Colors
        ...(ownerState.color === 'secondary' && {
          color: theme.palette.text.secondary,
          '&:hover': {
            backgroundColor: theme.palette.background.shaded,
            color: theme.palette.text.primary,
          },
        }),

        ...(ownerState.color === 'light' && {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.shaded,
          '&:hover': {
            backgroundColor: theme.palette.divider,
            color: theme.palette.text.primary,
          },
        }),

        ...(ownerState.color === 'black' && {
          backgroundColor: theme.palette.background.darkest,
          color: theme.palette.text.inverse,
          '&:hover': {
            backgroundColor: theme.palette.background.darker,
            color: theme.palette.divider,
          },
        }),

      }),
    },
  },
}