import { NavLink } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { Box, Stepper, Step, StepLabel, MenuItem, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { auth } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import logo from '../../assets/images/logo.svg'

const styles = {
  wrapper: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(220px + 3vw)',
    height: '100%',
    padding: '0 20px 24px',
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: { display: 'none', },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    padding: '16px',
  },
  stepper: {
    padding: '16px 0 0',
  },
  foot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
    height: 40,
    minHeight: 40,
    padding: '0 16px',
    borderRadius: '8px',
    lineHeight: 1,
    '& span': { fontWeight: 500, },
  },
}

const SetupSidebar = ({ steps, activeStep }) => {
  const handleSignOut = async () => {
    try {
      await signOut(auth)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.logo}>
        <img src={logo} alt='Hightag' style={{ width: '12vw', maxWidth: 136, minWidth: 128, }} />
      </Box>
      <Box sx={styles.body}>
        <Stepper sx={styles.stepper} activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={styles.foot}>
        <MenuItem component={NavLink} to='/signin' sx={styles.menuItem} onClick={handleSignOut}>
          <FontAwesomeIcon icon={icon({ name: 'arrow-right-from-bracket', family: 'sharp', style: 'regular', })} fixedWidth />
          <Typography variant='button1'>Sign out</Typography>
        </MenuItem>
      </Box>
    </Box>
  )
}

export default SetupSidebar