import { Box, Popover } from '@mui/material'

import NavMinorList from '../../components/_common/Nav/NavMinorList'

const HomeHeadPopover = ({ id, open, anchorEl, setAnchorEl }) => {
	const handleClosePopover = () => setAnchorEl(null)
	const styles = {
		popover: { '& .MuiPaper-root': { borderRadius: 2.5, }, },
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			paddingTop: 1,
			paddingLeft: 1,
			paddingRight: 1,
			paddingBottom: 1,
		},
	}

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClosePopover}
			sx={styles.popover}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Box sx={styles.wrapper}>
				<NavMinorList />
			</Box>
		</Popover>
	)
}
 
export default HomeHeadPopover