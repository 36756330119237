import { Box, IconButton } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { theme } from '../../themes/theme'

const LightboxImageControls = ({ media, index, setIndex, }) => {
	const styles = {
		thumbnailWrapper: {
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			gap: 0.75,
			height: '100%',
			[theme.breakpoints.down('md')]: { gap: 0.5, },
		},
		thumbnail: {
			flex: 1,
			height: 48,
			maxWidth: 48,
			cursor: 'pointer',
			[theme.breakpoints.down('md')]: {
				height: 44,
				maxWidth: 44,
			},
			'& img': {
				height: '100%',
				width: '100%',
				borderRadius: 1,
				objectFit: 'cover',
			},
		},
		thumbnailInactive: {
			'& img': {
				height: '100%',
				width: '100%',
				borderRadius: 1,
				objectFit: 'cover',
				opacity: 0.5,
			},
			'&:hover img': { opacity: 0.6, },
		},
		indicator: {
			position: 'absolute',
			zIndex: 2,
			top: 4,
			right: 4,
			overflow: 'hidden',
			filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,1))',
		},
		arrow: {
			height: '100%',
			width: 48,
			[theme.breakpoints.down('md')]: { width: 44, },
		},
	}

	// Create array of thumbnail images
	let thumbnailsArr = []
	media?.path.forEach(p => (
		p?.includes('rfid-pytest')
			? thumbnailsArr.push(p?.replace('storage.googleapis.com/rfid-pytest.appspot.com', 'ik.imagekit.io/hightag/tr:ar-1-1,h-96,q-50'))
			: thumbnailsArr.push(p?.replace('storage.googleapis.com/hightag-dev.appspot.com', 'ik.imagekit.io/hightag/tr:ar-1-1,h-96,q-50'))
	))

	// Markup to render thumbnails
	const thumbnails = (
		<Box sx={styles.thumbnailWrapper}>
			{thumbnailsArr.map((t, i) => {
				const isSelected = index === i
				const isCoverIndex = media?.cover_index === i
				const iconColor = isSelected ? theme.palette.text.inverse : theme.palette.grey[600]

				return (
					<Box sx={isSelected ? styles.thumbnail : { ...styles.thumbnail, ...styles.thumbnailInactive }} onClick={() => setIndex(i)} key={i}>
						{isCoverIndex && (
							<Box sx={styles.indicator}>
								<svg xmlns="http://www.w3.org/2000/svg" height="13" viewBox="0 0 19 16">
									<path fill={iconColor} d="M0.529541 0H2.52954H16.5295H18.5295V2V9V11H16.5295H2.52954H0.529541V9V2V0Z"/>
									<path fill={iconColor} d="M0.529541 16V13H3.52954V16H0.529541Z"/>
									<path fill={iconColor} d="M5.52954 13H8.52954V16H5.52954V13Z"/>
									<path fill={iconColor} d="M10.5295 16V13H13.5295V16H10.5295Z"/>
									<path fill={iconColor} d="M15.5295 13H18.5295V16H15.5295V13Z"/>
								</svg>
							</Box>
						)}
						<LazyLoadImage src={t} />
					</Box>
				)
			})}
		</Box>
	)

	// Handle index change on arrow clik
  const decrementIndex = () => {
		if (index > 0) setIndex(index - 1)
		else setIndex(thumbnailsArr?.length - 1)
	}
	const incrementIndex = () => {
		if (index < (thumbnailsArr?.length - 1)) setIndex(index + 1)
		else setIndex(0)
	}

	return (
		<>
			<IconButton sx={styles.arrow} color='black' onClick={decrementIndex}>
				<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill={theme.palette.text.inverse} d="M-1.9 256l17-17L207 47l17-17L257.9 64 241 81 65.9 256 241 431l17 17L224 481.9l-17-17L15 273l-17-17z"/></svg>
			</IconButton>
			{thumbnails}
			<IconButton sx={styles.arrow} color='black' onClick={incrementIndex}>
				<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill={theme.palette.text.inverse} d="M321.9 256l-17 17L113 465l-17 17L62.1 448l17-17 175-175L79 81l-17-17L96 30.1l17 17L305 239l17 17z"/></svg>
			</IconButton>
		</>
	)
}
 
export default LightboxImageControls