import { Box } from '@mui/material'

import { theme } from '../../themes/theme'

const AppBar = ({ left, center, right }) => {
	const styles = {
		wrapper: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			zIndex: 1000,
			display: 'flex',
			paddingTop: 0.25,
			paddingLeft: 1.5,
			paddingRight: 1.5,
			height: 70,
			width: '100%',
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.up('md')]: { display: 'none', },
		},
		content: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
			width: '100%',
		},
		left: {
			flex: 1,
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'center',
			gap: 1,
		},
		center: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 1,
		},
		right: {
			flex: 1,
			display: 'flex',
			justifyContent: 'right',
			alignItems: 'center',
			gap: 1,
			width: '25vw',
		},
	}

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.content}>
				<Box sx={styles.left}>{left}</Box>
				<Box sx={styles.center}>{center}</Box>
				<Box sx={styles.right}>{right}</Box>
			</Box>
		</Box>
	)
}
 
export default AppBar