import { useState, useEffect } from 'react'
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'

const LocationsMap = ({ devicesList, focus }) => {
	const [center, setCenter] = useState({ lat: 36.403617, lng: -94.201074 }) // The Castle
	const [devices, setDevices] = useState([])
	const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			position: 'fixed',
			top: 0,
			right: 0,
			zIndex: 1,
			height: '100svh',
			width: '33.3vw',
			[theme.breakpoints.down('md')]: {
				position: 'static',
				top: 'auto',
				left: 'auto',
				width: '100%',
				height: 400,
			},
		},
	}

	useEffect(() => {
		const devicesArr = []

		devicesList?.forEach(device => {
			const id = device.id
			const lat = device.lat
			const lng = device.lng
	
			if (id && lat && lng) devicesArr.push ({ ...device })
		})

		setDevices(devicesArr)
	}, [devicesList])

	useEffect(() => {
		if (focus && width > 640) setCenter({ lat: focus?.lat, lng: focus?.lng })
	}, [focus, width])
	
	return (
		<APIProvider apiKey='AIzaSyBizwxVtth8T_LB36UCueVr4PY1fJ8aCb0'>
			<Box sx={styles.wrapper}>
				<Map zoom={16} center={center} mapId='c441c0feedb33d25'>
					{devices?.map(device => {
						const id = device.id
						const lat = device.lat
						const lng = device.lng

						return <AdvancedMarker key={id} position={{ lat, lng }}><Pin /></AdvancedMarker>
					})}
				</Map>
			</Box>
		</APIProvider>
	)
}
 
export default LocationsMap