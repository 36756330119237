import { Box, Popover } from '@mui/material'

import TagPlansOption from './TagPlansOption'

const TagPlansPopover = ({ id, open, anchorEl, setAnchorEl, plans, selectedIndex, setSelectedIndex }) => {
	const styles = {
		popover: {
			'& .MuiPaper-root': {
				width: '100%',
				maxWidth: 360,
				borderRadius: 3,
			},
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			paddingTop: 12,
			paddingLeft: 12,
			paddingRight: 12,
			paddingBottom: 12,
		},
	}
	const handleClosePopover = () => setAnchorEl(null)

	const plansList = plans.map((plan, index) => (
		<TagPlansOption
			key={index}
			plan={plan}
			plans={plans}
			selectedIndex={selectedIndex}
			setSelectedIndex={setSelectedIndex}
			handleClosePopover={handleClosePopover}
		/>
	))

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClosePopover}
			sx={styles.popover}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			children={<Box style={styles.wrapper}>{plansList}</Box>}
		/>
	)
}
 
export default TagPlansPopover