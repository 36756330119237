import { doc, getDoc, collection, query, where, getDocs, setDoc, deleteDoc } from '@firebase/firestore'
import { db } from '../firebase/initialize'

export const attributeMediaToUser = async tagEPC => {
	try {
		const tagDocRef = doc(db, 'tags', tagEPC)
		const tagSnap = await getDoc(tagDocRef)

		if (!tagSnap.exists) {
			console.log(`Tag does not exist in the database.`)
			return
		}

		const tagData = tagSnap.data()
		const tagAlias = tagData?.tag_alias
		const tagUser = tagData?.user_id
	
		console.log(`Searching for media upload documents related to tag ${tagAlias}`)

		const mediaUploadColRef = collection(db, 'media_upload')
		const mediaUploadQryRef = query(mediaUploadColRef, where('tag_EPC', '==', tagEPC))
		const mediaUploadSnap = await getDocs(mediaUploadQryRef)

		if (mediaUploadSnap.empty) {
			console.log(`There are no media upload documents related to tag ${tagAlias}.`)
			return
		}

		for (const mediaDoc of mediaUploadSnap.docs) { // Using `for` instead of `forEach` to properly handle async
			const id = mediaDoc.id
			const data = { ...mediaDoc.data(), user_id: tagUser }
	
			await setDoc(doc(db, 'user_media_inactive', id), data)
			await deleteDoc(doc(db, 'media_upload', id))
		}
	} catch (error) {
		console.error(`Error attributing media to user: ${error}`)
	}
}