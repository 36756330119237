import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider, Button, alpha } from '@mui/material'
import { SnackbarProvider, closeSnackbar } from 'notistack'

import configureStore from './redux/store'
import FlutterMessagesProvider from './contexts/FlutterMessagesContext'
import { theme } from './themes/theme'
import App from './App'

const store = configureStore()

const styles = {
  snackbarDismiss: {
    height: 32,
    padding: '0 12px',
    fontWeight: 500,
    color: theme.palette.text.inverse,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
      color: theme.palette.common.white,
    },
  },
}

const renderApp = () => {
  let root = createRoot(document.getElementById('root'))

  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <FlutterMessagesProvider>
          <SnackbarProvider
            classes={{ containerRoot: 'z-alert' }}
            action={(snackbarId) => (
              <Button sx={styles.snackbarDismiss} onClick={() => closeSnackbar(snackbarId)} disableRipple>Dismiss</Button>
            )}
          >
            <App />
          </SnackbarProvider>
        </FlutterMessagesProvider>
      </ThemeProvider>
    </Provider>
  )
}

renderApp()