import { Modal, Fade, Card, CardHeader, CardContent, Typography, CardActions, Button, IconButton } from '@mui/material'
import { doc, updateDoc } from '@firebase/firestore'
import { useSnackbar } from 'notistack'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'

const LightboxActionDelete = ({ deleteModalOpen, setDeleteModalOpen, setDropdownAnchor, media, handleCloseModal }) => {
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			paddingLeft: 3,
			paddingRight: 3,
		},
	}
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setDeleteModalOpen(false)
    setDropdownAnchor(null)
  }

	const handleDelete = async () => {
    const mediaDocRef = doc(db, 'media', media?.id)

		try {
      await updateDoc(mediaDocRef, { hidden: true, })
      handleCloseModal()
      return enqueueSnackbar('Successfully deleted media.', { variant: 'success' })
    } catch (error) {
      console.error(error)
      return enqueueSnackbar('Something went wrong, please try again.', { variant: 'error' })
    }
	}

  return (
    <Modal sx={styles.wrapper} open={deleteModalOpen} onClose={handleClose}>
      <Fade in={deleteModalOpen}>
        <div>
          <Card>
            <CardHeader
                title={<Typography sx={{ fontSize: 18, fontWeight: 600 }}>Delete photo set</Typography>}
                action={<IconButton onClick={handleClose}>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill={theme.palette.text.secondary} d="M70.6 57.4L48 34.7 2.7 80l22.6 22.6L178.7 256 25.4 409.4 2.7 432 48 477.3l22.6-22.6L224 301.3 377.4 454.6 400 477.3 445.3 432l-22.6-22.6L269.3 256 422.6 102.6 445.3 80 400 34.7 377.4 57.4 224 210.7 70.6 57.4z"/></svg>
                        </IconButton>}
            />
            <CardContent>
              <Typography>Are you sure you want to delete this set of photos?</Typography>
            </CardContent>
            <CardActions>
              <Button variant='contained' color='error' onClick={handleDelete} disableRipple>Delete</Button>
              <Button variant='contained' color='secondary' onClick={handleClose} disableRipple>Cancel</Button>
            </CardActions>
          </Card>
        </div>
      </Fade>
    </Modal>
  )
}
 
export default LightboxActionDelete