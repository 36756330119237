import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, } from '@mui/material'

import { theme } from '../../themes/theme'
import TagPlansCard from './TagPlansCard'

const TagPlansDesktop = () => {
	const user = useSelector(state => state.rootReducer.user.data)
	const [generalLoading, setGeneralLoading] = useState(false)
	const membershipPlans = [
		{
			title: 'Monthly',
			price: '$11.99 per month',
			priceId: 'price_1OF7OfEeQCzVPMOZFRtaCkf1',
		},
		{
			title: 'Yearly',
			price: '$69.99 per year',
			priceId: 'price_1OHpu0EeQCzVPMOZTAyy80EP',
		},
	]
	const dayPassPlans = [
		{
			title: 'Day Pass',
			price: '$6.99',
			priceId: 'price_1OHpsFEeQCzVPMOZJ1tZZ2eh',
		},
	]
	const styles = {
		wrapper: {
			display: 'flex',
			justifyContent: 'center',
			gap: 2,
			width: '100%',
		},
		card: {
			flex: 1,
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			padding: 4,
			paddingBottom: 3,
			maxWidth: 400,
			backgroundColor: theme.palette.background.default,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 4,
		},
		preferred: {
			backgroundColor: theme.palette.primary.light,
			borderColor: '#FFBCAE',
		},
	}

	return (
		<Box sx={styles.wrapper}>
			<TagPlansCard
				title={'Membership'}
				plans={membershipPlans}
				generalLoading={generalLoading}
				benefits={[
					...!user?.trialed ? ['1-day FREE trial for new members'] : [],
					'Utilize all Hightag cameras',
					'Access all your Hightag photos—forever',
				]}
				setGeneralLoading={setGeneralLoading}
				includeTrial={!user?.trialed}
				preferred
				switcher
			/>
			<TagPlansCard
				title={'Day Pass'}
				plans={dayPassPlans}
				generalLoading={generalLoading}
				benefits={[
					'Utilize all Hightag cameras for 24 hours',
					'Access all your Hightag photos—forever',
				]}
				setGeneralLoading={setGeneralLoading}
			/>
		</Box>
	)
}
 
export default TagPlansDesktop