import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './reducers/rootReducer'
// import monitorReducersEnhancer from './enhancers/monitorReducers'
import userListener from './middleware/userListener'
// import devicesListener from './middleware/devicesListener'
// import loggerMiddleware from './middleware/logger'

const configureAppStore = (preloadedState) => {
  const store = configureStore({
    reducer: {
      rootReducer,
    },
    middleware: (getDefaultMiddleware) => (
      // getDefaultMiddleware({ serializableCheck: false }).concat(userListener.middleware, loggerMiddleware)
      // getDefaultMiddleware({ serializableCheck: false }).concat(userListener.middleware, devicesListener.middleware)
      getDefaultMiddleware({ serializableCheck: false }).concat(userListener.middleware)
    ),
    preloadedState,
    // enhancers: [monitorReducersEnhancer]
  })

  return store
}

export default configureAppStore