import { Box, Button, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import { Link } from 'react-router-dom'

const Missing = () => {
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			width: '100%',
			paddingTop: 0,
			paddingLeft: 4,
			paddingRight: 4,
			paddingBottom: 3,
			textAlign: 'center',
		},
		title: {
			fontSize: '30vw',
			lineHeight: 0.95,
			color: theme.palette.text.disabled,
			[theme.breakpoints.down('md')]: { fontSize: '40vw', lineHeight: 1, },
		},
		subtitle: {
			marginBottom: 4,
			fontSize: 24,
			color: theme.palette.text.secondary,
			[theme.breakpoints.down('sm')]: { fontSize: 22, },
		},
	}

  return (
    <Box sx={styles.wrapper}>
      <Typography variant='h1' sx={styles.title}>404</Typography>
      <Typography variant='subtitle1' sx={styles.subtitle}>
        Hmmm... it looks like that page doesn't exist. Let's get you back to one that does.
      </Typography>
      <Button variant='contained' color='secondary' size='large' component={Link} to='/' disableRipple>Back to home</Button>
    </Box>
  )
}

export default Missing