export const groupMedia = media => {
  if (!media?.length) return
  
  const groupsObj = Object.values(media).reduce((groups, item) => {
    if (!groups[item.date]) groups[item.date] = []
    groups[item.date].push(item)
    return groups
  }, {})

  const groupsArr = Object.keys(groupsObj).map(date => {
    return { date, media: groupsObj[date] }
  })

  const sortedGroups = groupsArr.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })

  return sortedGroups
}