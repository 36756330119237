import { Stack, Box } from '@mui/material'

import { theme } from '../../themes/theme'
import LocationsHeader from './LocationsHeader'
import LocationsListItem from './LocationsListItem'

const LocationsList = ({ locations, devicesList, focus, setFocus }) => {
	const styles = {
		wrapper: {
			position: 'relative',
			zIndex: 2,
			minHeight: '100svh',
			width: 'calc(66.7vw - 280px)',
			minWidth: 320,
			backgroundColor: theme.palette.background.default,
			boxShadow: `0px 8px 48px -8px rgba(0,0,0,0.16)`,
			[theme.breakpoints.down('md')]: {
				height: 'auto',
				width: '100vw',
				minWidth: 0,
			},
		},
		corners: {
			position: 'absolute',
			top: 70,
			left: 20,
			right: 20,
			zIndex: 3,
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: '-14px',
		},
		corner: {
			height: 16,
			aspectRatio: '1 / 1',
		},
		list: {
			position: 'relative',
			zIndex: 1,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
			marginTop: 0,
			paddingLeft: 2.5,
			paddingRight: 2.5,
			paddingBottom: 5,
			overflowY: 'auto',
			[theme.breakpoints.down('md')]: {
				marginTop: 3,
				paddingBottom: 20,
			},
		},
	}

	return (
		<Stack sx={styles.wrapper}>
			<LocationsHeader locations={locations} devicesList={devicesList} />
			{/* <Box sx={styles.corners}>
				<Box sx={styles.corner}>
					<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60" fill="none"><path fill={theme.palette.background.default} d="M60 0H0V60C0 26.8628 26.8628 0 60 0Z"/></svg>
				</Box>
				<Box sx={styles.corner}>
					<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60" fill="none"><path fill={theme.palette.background.default} d="M0 0H60V60C60 26.8628 33.1372 0 0 0Z"/></svg>
				</Box>
			</Box> */}
			<Box sx={styles.list}>
				{locations?.map((location, index) => (
					<LocationsListItem
						location={location}
						key={index}
						index={index}
						focus={focus}
						setFocus={setFocus}
					/>
				))}
			</Box>
		</Stack>
	)
}
 
export default LocationsList