import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const HomeFavoritesEmpty = () => {
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: 3,
			width: '100%',
			height: 'calc((100svh - 8px - 72px - 72px - 32px) * 0.3)',
			borderRadius: 3,
			backgroundColor: theme.palette.background.shaded,
		},
	}

	return (
		<Box sx={styles.wrapper}>
			<Typography color={theme.palette.text.secondary} variant='body2' children="You haven't favorited any photos yet" />
		</Box>
	)
}
 
export default HomeFavoritesEmpty