import { createListenerMiddleware } from '@reduxjs/toolkit'

import { setUserSucceeded } from '../reducers/userSlice'
import { fetchLocations } from '../actions/locationsActions'
import { fetchTags } from '../actions/tagsActions'
import { fetchMedia } from '../actions/mediaActions'

const userListener = createListenerMiddleware()

userListener.startListening({
  actionCreator: setUserSucceeded,
  effect: async (action, listenerApi) => {
    const user = action.payload

    await listenerApi.dispatch(fetchLocations(user))
    await listenerApi.dispatch(fetchTags(user))
    await listenerApi.dispatch(fetchMedia(user))
  }
})

export default userListener