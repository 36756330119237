import { signOut } from 'firebase/auth'
import { Box, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { auth } from '../../firebase/initialize'
import logo from '../../assets/images/logo.svg'
import { theme } from '../../themes/theme'

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 0 40px',
    [theme.breakpoints.up('sm')]: { display: 'none', }
  },
  logo: { height: 28, },
}

const SetupHeader = () => {
  const handleSignOut = async () => {
    try {
      await signOut(auth)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box sx={styles.wrapper}>
      <img style={styles.logo} src={logo} alt='Hightag' />
      <Button variant='contained' color='secondary' onClick={handleSignOut} disableRipple>
        Sign out
        <FontAwesomeIcon icon={icon({ name: 'arrow-right-from-bracket', family: 'sharp', style: 'regular', })} fixedWidth />
      </Button>
    </Box>
  )
}

export default SetupHeader