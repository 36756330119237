import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import AccountDesktop from './AccountDesktop'
import AccountMobile from './AccountMobile'

const Account = () => {
  const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: 3,
			paddingTop: 3,
			paddingLeft: 3,
			paddingRight: 3,
			paddingBottom: 5,
			[theme.breakpoints.down('md')]: {
				gap: 2.5,
				paddingTop: 0,
				paddingLeft: 2.5,
				paddingRight: 2.5,
				paddingBottom: 14,
			},
			[theme.breakpoints.down('sm')]: {
				gap: 2,
				paddingLeft: 2,
				paddingRight: 2,
			},
		},
	}

	return (
    <Box sx={styles.wrapper}>
      {width > 998 ? <AccountDesktop /> : <AccountMobile />}
    </Box>
	)
}

export default Account