import { alpha, Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const LocationsHeader = ({ locations, devicesList, modalOpen }) => {
	const styles = {
		head: {
			position: 'sticky',
			top: 0,
			zIndex: 10,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 1,
			height: 70,
			minHeight: 70,
			width: '100%',
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.down('md')]: {
				position: 'sticky',
				height: 64,
				minHeight: 64,
				transition: 'box-shadow 0.4s ease',
				boxShadow: modalOpen ? 'none' : `0 8px 24px -8px ${alpha(theme.palette.background.darker, 0.1)}`,
			},
		},
		divider: {
			width: 6,
			height: 6,
			borderRadius: '50%',
			backgroundColor: theme.palette.grey[500],
		},
	}
	
	return (
		<Box sx={styles.head}>
			<Typography variant='h4' color={theme.palette.neutrals[100]} children={`${locations?.length} locations • 18 cameras`} />
		</Box>
	)
}
 
export default LocationsHeader