import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const LightboxInfo = ({ icon, major, minor }) => {
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: 1.5,
			paddingTop: 1.5,
			paddingLeft: 2,
			paddingRight: 2,
			paddingBottom: 1.5,
			borderRadius: 2.5,
		},
		link: {
			textDecoration: 'none',
			'&:hover': {
				backgroundColor: theme.palette.background.shaded,
			},
		},
		content: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
		},
		major: {
			fontSize: 15,
			lineHeight: 1.3,
			color: theme.palette.heading.primary,
		},
		minor: {
			lineHeight: 1.3,
			color: theme.palette.text.secondary,
		},
	}

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.icon}>{icon}</Box>
      <Box sx={styles.content}>
        <Typography sx={styles.major} variant='subtitle3'>{major}</Typography>
        <Typography sx={styles.minor} variant='body3'>{minor}</Typography>
      </Box>
    </Box>
  )
}
 
export default LightboxInfo