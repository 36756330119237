import { Box } from '@mui/material'

import { theme } from '../../themes/theme'

const Main = ({ children }) => {
	const styles = {
		main: {
			flex: 1,
			position: 'relative',
			zIndex: 1,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			width: 'calc(100% - 280px)',
			marginLeft: 35,
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.down('md')]: {
				flex: 0,
				marginLeft: 0,
				marginBottom: 0,
				// height: 'calc(100% - 80px)',
				width: '100%',
				scrollbarWidth: 'none',
				'&::-webkit-scrollbar': { display: 'none' },
			},
		},
	}
	
	return (
    <Box sx={styles.main} id='main'>
      {children}
    </Box>
  )
}

export default Main