import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { collection, getDocs, query, doc, updateDoc, where } from 'firebase/firestore'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import { mixpanel } from '../../mixpanel/initialize'
import { attributeMediaToUser } from '../../utilities/attributeMediaToUser'

const TagStatusUnlinked = () => {
  // Test alias: D2YSB0Z7
  const user = useSelector(state => state.rootReducer.user.data)
  const [loading, setLoading] = useState(false)
  const aliasRef = useRef()
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 1.5,
			width: '100%',
			maxWidth: 360,
			[theme.breakpoints.down('sm')]: {
				flex: 1,
				justifyContent: 'flex-end',
			},
		},
		input: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 56,
			width: '100%',
			borderRadius: 2.5,
			border: `1px solid ${theme.palette.divider}`,
			textAlign: 'center',
			fontSize: 22,
			letterSpacing: '0.3em',
			fontWeight: 500,
			lineHeight: 1,
			color: theme.palette.text.primary,
			textTransform: 'uppercase',
			boxShadow: 'inset 0px 6px 10px -6px rgba(0, 0, 0, 0.08)',
			'& *': {
				fontWeight: 500,
				width: '100%',
				maxWidth: 400,
			},
			'&::placeholder': {
				fontWeight: 500,
				color: theme.palette.text.disabled,
			},
		},
	}

	const handleSubmit = async () => {
    setLoading(true)

    try {
			const tagsColRef = collection(db, 'tags')
			const tagsQryRef = query(tagsColRef, where('tag_alias', '==', aliasRef.current.value.toUpperCase()))
      const tagsSnap = await getDocs(tagsQryRef)

      if (tagsSnap.empty) {
				enqueueSnackbar(`The tag ID you entered isn't valid.`, { variant: 'error' })
				return setLoading(false)
			}

      tagsSnap.forEach(async tag => {
				const tagId = tag.id
				const tagData = tag.data()
        const tagDocRef = doc(db, 'tags', tagId)

        if (tagData.user_id) {
					enqueueSnackbar(`This tag is already linked to a different user.`, { variant: 'error' })
					return setLoading(false)
				}

				if (!tagData?.hasBeenRegistered) attributeMediaToUser(tagId)

        await updateDoc(tagDocRef, { user_id: user.id, hasBeenRegistered: true, })
				enqueueSnackbar(`Your tag has been linked to your account!`, { variant: 'success' })
        mixpanel.track('Add Tag')
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
      enqueueSnackbar(`Something went wrong. Please try again.`, { variant: 'error' })
      setLoading(false)
    }
	}

  return (
    <Box sx={styles.wrapper}>
      <Box
				component='input'
				sx={styles.input}
				type='text'
				placeholder='&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;'
				ref={aliasRef}
				maxLength="8"
				required
      />
      <Button variant='contained' color='primary' size='large' sx={{ width: '100%' }} onClick={handleSubmit} disableRipple>
        {loading
					? <CircularProgress color='secondary' size={24} />
					: <Typography variant='button1'>Add tag</Typography>}
      </Button>
    </Box>
  )
}

export default TagStatusUnlinked