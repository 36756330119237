import { Box, Typography, OutlinedInput } from '@mui/material'

import { theme } from '../../themes/theme'

const TextInput = ({ name, label, values, placeholder, type = 'text', onChange, errors, disabled, required, span, align = 'left', }) => {
  const styles = {
    wrapper: {
      gridColumn: `span ${span}`,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 0.25,
      [theme.breakpoints.down('md')]: { gridColumn: 'span 6', },
    },
    label: { color: theme.palette.text.secondary, },
    input: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      lineHeight: 1,
			boxShadow: 'inset 0px 6px 10px -6px rgba(0, 0, 0, 0.08)',
      '& input': {
        height: 40,
        margin: 0,
				paddingLeft: '12px !important',
				paddingRight: '12px !important',
        borderRadius: 1.5,
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: 'transparent',
        textAlign: align,
        [theme.breakpoints.down('md')]: { height: 48, },
      },
      '& fieldset': { display: 'none', },
      '&.Mui-disabled': {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
        backgroundColor: theme.palette.background.default,
				boxShadow: 'none',
      },
    },
  }

	return (
    <Box sx={styles.wrapper}>
      <Typography variant='overline' sx={styles.label}>{label}</Typography>
      <OutlinedInput
        sx={styles.input}
        inputProps={{ style: { padding: '0 8px', } }}
        type={type}
        name={name}
        value={values?.[name]}
        error={!!errors?.[name]}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
      />
    </Box>
	)
}

export default TextInput