import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {
    setUserRequested: (state) => {
      state.isLoading = true
      state.error = null
    },
    setUserSucceeded: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },
    setUserFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const { setUserRequested, setUserSucceeded, setUserFailed } = userSlice.actions
export default userSlice.reducer