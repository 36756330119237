export const Link = {
  MuiLink: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        cursor: 'pointer',

      }),
    },
  },
}