import { createSlice } from '@reduxjs/toolkit'

const inactiveMediaSlice = createSlice({
  name: 'inactiveMedia',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {
    setInactiveMediaRequested: (state) => {
      state.isLoading = true
      state.error = null
    },
    setInactiveMediaSucceeded: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },
		setInactiveMediaEmpty: (state) => {
			state.isLoading = false
			state.data = []
			state.error = null
		},
    setInactiveMediaFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const { setInactiveMediaRequested, setInactiveMediaSucceeded, setInactiveMediaEmpty, setInactiveMediaFailed } = inactiveMediaSlice.actions
export default inactiveMediaSlice.reducer