import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import ActivationTimer from '../_common/ActivationTimer'
import SubscriptionDetails from '../_common/SubscriptionDetails'
import SubscriptionPortalButton from '../_common/SubscriptionPortalButton'

const TagStatusActive = () => {
	const user = useSelector(state => state.rootReducer.user.data)
	const activationType = user?.activation_type
	const styles = {
		wrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			maxWidth: 376,
			height: 'auto',
			[theme.breakpoints.down('sm')]:
				activationType === 'Day Pass'
				? {
					flex: 1,
					justifyContent: 'flex-end',
					maxWidth: 'none',
				}
				: {
					flex: 1,
					justifyContent: 'flex-end',
				},
		},
		membership: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 1.5,
		},
	}

	const dayPassMarkup = <ActivationTimer />
	const membershipMarkup = (
		<Box sx={styles.membership}>
			<SubscriptionDetails />
			<SubscriptionPortalButton message='Manage my subscription with' />
		</Box>
	)

  return (
    <Box sx={styles.wrapper}>
			{activationType === 'Day Pass' ? dayPassMarkup : membershipMarkup}
    </Box>
	)
}

export default TagStatusActive