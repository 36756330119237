import { collection, query, where, onSnapshot } from 'firebase/firestore'

import { db } from '../../firebase/initialize'
import { setInactiveMediaRequested, setInactiveMediaSucceeded, setInactiveMediaFailed } from '../reducers/inactiveMediaSlice'

export const fetchInactiveMedia = (user) => async (dispatch) => {
  dispatch(setInactiveMediaRequested())

  // Disallow retroactivation if user has previously activated
  if (user?.hasBeenActivated) {
    dispatch(setInactiveMediaSucceeded(null))
    return
  }

  try {
    const inactiveColRef = collection(db, 'user_media_inactive')
    const inactiveQryRef = query(inactiveColRef, where('user_id', '==', user.id))

    onSnapshot(inactiveQryRef, (inactiveMediaSnap) => {
      try {
        let inactiveMediaArr = []

        inactiveMediaSnap.forEach(doc => {
          const id = doc.id
          const data = doc.data()
          inactiveMediaArr.push({ id, data })
        })

        inactiveMediaArr.sort((a, b) => {
          const dateA = a.data.createdAt?.seconds ?? 0
          const dateB = b.data.createdAt?.seconds ?? 0
          return dateB - dateA
        })

        dispatch(setInactiveMediaSucceeded(inactiveMediaArr))
      } catch (error) {
        console.error('Snapshot processing error:', error)
        dispatch(setInactiveMediaFailed(error.message))
      }
    })
  } catch (error) {
    console.error('Firestore error:', error)
    dispatch(setInactiveMediaFailed(error.message))
  }
}