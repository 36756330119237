import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDebounce } from 'use-debounce'

import { checkUsernameAvailability } from '../../utilities/checkUsernameAvailability'
import TextInput from '../_common/TextInput'
import SetupForm from './SetupForm'

const SetupUsername = ({ values, setActiveStep, setValues, batch }) => {
  const user = useSelector(state => state.rootReducer.user.data)
  const [usernameAllowed, setUsernameAllowed] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
  const [usernameInput] = useDebounce(values?.username, 200)

  useEffect(() => {
    if (usernameInput) {
      checkUsernameAvailability(usernameInput)
        .then((isAvailable) => {
          const isCurrentUsername = usernameInput === user.username
          setUsernameAllowed(isAvailable || isCurrentUsername)
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' })
        })
    }
  }, [usernameInput, user.username, enqueueSnackbar])

  const handleNext = () => {
    let errors = []

    if (!values?.username) errors.push({ message: `Username can't be empty.` })
    if (values?.username && (usernameInput.length < 4 || usernameInput.length > 20)) errors.push({ message: `Username must be between 4 and 20 characters.` })
    if (values?.username && !/^(?![_.-])[a-zA-Z0-9_.-]*(?:[a-zA-Z0-9_]|[_])$/.test(values?.username)) errors.push({ message: `Usernames can only contain letters, numbers, "-", "_", and "."` }) 
    if (values?.username && !usernameAllowed) errors.push({ message: `Username is already taken.` })

    if (errors?.length) {
      errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      return errors = []
    } else {
      setActiveStep(prevState => prevState + 1)
    }
  }

  return (
    <SetupForm
      title='Welcome to Hightag'
      message="Let's get started! Pick a username for your profile."
      children={(
        <>
          <TextInput
            name='username'
            placeholder='Username' 
            span={6}
            align='center'
            values={values}
            required
            onChange={e => setValues(prevState => ({ ...prevState, username: e.target.value }))}
          />
        </>
      )}
      actions={(
        <>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            onClick={() => setActiveStep(prevState => prevState - 1)}
            disabled={true}
            children='Back'
						disableRipple
          />
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={handleNext}
            disabled={!values?.username}
            children='Next'
						disableRipple
          />
        </>
      )}
    />
  )
}

export default SetupUsername