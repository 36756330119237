import { colors } from './variables'

export const palette = {
  palette: {
    mode: 'light',
    primary: {
      main: colors.orange.base,
      light: colors.orange.light,
      dark: colors.orange.dark,
    },
    secondary: {
      main: colors.snow,
      light: colors.snow,
      dark: colors.steel,
      contrastText: colors.cadet,
    },
    error: {
      main: colors.red,
    },
    warning: {
      light: colors.yellow.light,
      main: colors.yellow.base,
      dark: colors.yellow.dark,
    },
    info: {
      light: colors.blue.light,
      main: colors.blue.base,
    },
    success: {
      main: colors.green.base,
      light: colors.green.light,
      contrastText: colors.white,
    },
		heading: {
			primary: colors.space,
			secondary: colors.spaceCadet,
		},
    text: {
      primary: colors.cadet,
      secondary: colors.slate,
      disabled: colors.gray,
			inverse: colors.white,
    },
    divider: colors.steel,
    background: {
      paper: colors.white,
      default: colors.snowWhite,
			shaded: colors.snow,
			dark: colors.cadet,
			darker: colors.space,
			darkest: colors.black,
    },
		inherit: {},
		neutrals: {
			black: colors.black,
			100: colors.space,
			200: colors.spaceCadet,
			300: colors.cadet,
			400: colors.slate,
			500: colors.gray,
			600: colors.steel,
			700: colors.snow,
			800: colors.snowWhite,
			white: colors.white,
		},
  },
}