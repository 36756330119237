import { useState } from 'react'
import { Button, Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import TagBenefits from './TagBenefits'
import TagPlansPopover from './TagPlansPopover'
import TagCheckout from './TagCheckout'

const TagPlansMobile = () => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const open = Boolean(anchorEl)
	const plans = [
		{
			title: 'Yearly Membership',
			price: '$69.99 per year',
			priceId: 'price_1OHpu0EeQCzVPMOZTAyy80EP',
		},
		{
			title: 'Monthly Membership',
			price: '$11.99 per month',
			priceId: 'price_1OF7OfEeQCzVPMOZFRtaCkf1',
		},
		{
			title: 'Day Pass',
			price: '$6.99',
			priceId: 'price_1OHpsFEeQCzVPMOZJ1tZZ2eh',
		},
	]
	const styles = {
		wrapper: {
			justifyContent: 'space-between',
			paddingLeft: 2,
			paddingRight: 2,
			width: '100%',
			height: 72,
			borderRadius: 2.5,
			backgroundColor: theme.palette.background.paper,
		},
		details: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		title: { color: theme.palette.primary.dark, },
		more: {
			display: 'flex',
			alignItems: 'center',
			gap: 0.75,
		},
	}

	const handleOpenPopover = (e) => setAnchorEl(e.currentTarget)

	return (
		<>
			<TagBenefits />
			<Button
				sx={styles.wrapper}
				variant='outlined'
				color='inherit'
				size='large'
				onClick={handleOpenPopover}
				disableRipple
			>
				<Box sx={styles.details}>
					<Typography variant='subtitle3' sx={styles.title} children={plans[selectedIndex].title} />
					<Typography variant='body3' children={plans[selectedIndex].price} />
				</Box>
				<Box sx={styles.more}>
					<Typography variant='body4' children='More options' />
					<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 320 512"><path fill={theme.palette.text.primary} d="M320 240L160 384 0 240l0-48 320 0 0 48z"/></svg>
				</Box>
			</Button>
			<TagCheckout plans={plans} selectedIndex={selectedIndex} />
			<TagPlansPopover
				open={open}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				plans={plans}
				selectedIndex={selectedIndex}
				setSelectedIndex={setSelectedIndex}
			/>
		</>
	)
}
 
export default TagPlansMobile