import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import TagAliasModal from './TagAliasModal'

const TagAlias = () => {
  const tags = useSelector(state => state.rootReducer.tags.data)
  const [modalOpen, setModalOpen] = useState(false)
  const tagAlias = tags?.[0]?.tag_alias
  let string = tagAlias ? tagAlias : 'No tag found'
	const styles = {
		wrapper: {
			position: 'relative',
			zIndex: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 2,
			width: 200,
			height: 42,
			borderRadius: 10,
			border: `1px solid ${theme.palette.divider}`,
			backgroundColor: theme.palette.background.default,
		},
		wrapperActive: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: `0px 2px 10px -2px rgba(0,0,0,0.06),
									0px 1px 2px -1px rgba(0,0,0,0.12),
									inset 0 6px 12px rgba(255,255,255,0.03),
									inset 0 1px 1px rgba(255,255,255,0.06) !important`,
		},
		alias: {
			fontSize: 13.5,
			textTransform: 'uppercase',
			letterSpacing: '0.1em',
			color: theme.palette.text.secondary,
		},
		aliasActive: {
			fontSize: 15,
			letterSpacing: '0.3em',
			color: theme.palette.text.primary,
		},
		unlink: {
			position: 'absolute',
			right: 2,
			width: 36,
			height: 36,
			borderRadius: '50%',
		},
	}

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  return (
    <Box sx={tagAlias ? { ...styles.wrapper, ...styles.wrapperActive } : styles.wrapper}>
      <Typography sx={tagAlias ? { ...styles.alias, ...styles.aliasActive } : styles.alias}>{string}</Typography>
      {tagAlias &&
        <IconButton sx={styles.unlink} onClick={handleOpenModal}>
          <svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewBox="0 0 640 512"><path fill={theme.palette.text.secondary} d="M48.4 14.8L29.4 .1 0 38 19 52.7 591.5 497.2l19 14.7L639.9 474l-19-14.7-87.5-68C578.2 363 608 313 608 256c0-88.4-71.6-160-160-160H384 352v64h32 64c53 0 96 43 96 96c0 42.9-28.2 79.3-67 91.5L400.3 288H416h32V224H416 317.9l-82.4-64H256h32V96H256 192c-11.8 0-23.2 1.3-34.3 3.7L48.4 14.8zM70.7 151.7C46.6 179.7 32 216.1 32 256c0 88.4 71.6 160 160 160h64 32V352H256 192c-53 0-96-43-96-96c0-24.9 9.5-47.6 25-64.6L70.7 151.7z"/></svg>
        </IconButton>}
      <TagAliasModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </Box>
  )
}
 
export default TagAlias