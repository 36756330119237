import { useState, useRef } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import { IconButton, Box } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useSnackbar } from 'notistack'

import { db } from '../../firebase/initialize'
import { theme, global } from '../../themes/theme'
import { getMediaThumbnail } from '../../utilities/getMediaThumbnail'
import Lightbox from '../lightbox/Lightbox'

const MediaThumbnail = ({ mediaItem = {} }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const linkRef = useRef(null)
  const wrapperRef = useRef(null)
  const isFavorite = mediaItem?.favorite
	const thumbnail = getMediaThumbnail(mediaItem)
	const fileType = mediaItem?.fileType
  const mediaDocRef = doc(db, 'media', mediaItem?.id)
	const styles = {
		wrapper: {
			position: 'relative',
			zIndex: 1,
			width: '100%',
			aspectRatio: '1 / 1',
			overflow: 'hidden',
			backgroundColor: theme.palette.background.default,
			cursor: 'pointer',
			[theme.breakpoints.up('lg')]: {
				transition: 'opacity 0.2s ease-out',
				'&:hover': {
					opacity: 0.9,
					'& .favorite': { opacity: 1, },
				},
			},
		},
		thumbnail: {
			position: 'relative',
			zIndex: 1,
			backgroundColor: theme.palette.divider,
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		type: {
			position: 'absolute',
			right: 16,
			bottom: 14,
			[theme.breakpoints.down('sm')]: {
				right: 10,
				bottom: 10,
				'& > svg': { fontSize: 13, },
			},
		},
	}

	const handleClick = () => setOpen(true)

  const handleFavorite = async () => {
    const doc = { favorite: isFavorite ? false : true }

    try {
      return await updateDoc(mediaDocRef, doc)
    } catch (error) {
      console.error(error)
      return enqueueSnackbar('Something went wrong', { variant: 'error' })
    }
  }

	let typeIcon
	// eslint-disable-next-line
	switch (fileType) {
		case 'image':
			typeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18"><path fill={theme.palette.text.inverse} d="M6.3897 15.4257H15.4199V6.41564V3.84134H18V6.41564V15.4257V18H15.4199H6.3897H3.80963V15.4257H6.3897ZM0 14.1587V0H14.1904V14.1587H0Z"/></svg>
			break
		case 'video':
			typeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 18"><path fill={theme.palette.text.inverse} d="M15.5 9L0.5 0.25V17.75L15.5 9Z"/></svg>
			break
	}

	return (
    <Box sx={styles.wrapper} id={mediaItem.id} ref={linkRef}>
      <Lightbox open={open} setOpen={setOpen} media={mediaItem} initialIndex={thumbnail?.coverIndex} />
      <Box
        sx={styles.thumbnail}
        ref={wrapperRef}
        onClick={handleClick}
        children={(<LazyLoadImage src={thumbnail?.path} effect='blur' alt='Captured media' />)}
      />
      <Box sx={global.styles.favoritor} className={isFavorite ? 'favorite solid' : 'favorite'}>
        <IconButton color='light' size='small' onClick={handleFavorite}>
          {isFavorite ? 
            (<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill={theme.palette.primary.dark} d="M39.8 263.8L64 288 256 480 448 288l24.2-24.2c25.5-25.5 39.8-60 39.8-96C512 92.8 451.2 32 376.2 32c-36 0-70.5 14.3-96 39.8L256 96 231.8 71.8c-25.5-25.5-60-39.8-96-39.8C60.8 32 0 92.8 0 167.8c0 36 14.3 70.5 39.8 96z"/></svg>) :
            (<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill={theme.palette.text.primary} d="M256 163.9L222.1 130l-24.2-24.2C181.4 89.3 159 80 135.8 80C87.3 80 48 119.3 48 167.8c0 23.3 9.2 45.6 25.7 62.1l24.2 24.2L256 412.1 414.1 254.1l24.2-24.2c16.5-16.5 25.7-38.8 25.7-62.1c0-48.5-39.3-87.8-87.8-87.8c-23.3 0-45.6 9.2-62.1 25.7L289.9 130 256 163.9zm33.9 282.2L256 480l-33.9-33.9L64 288 39.8 263.8C14.3 238.3 0 203.8 0 167.8C0 92.8 60.8 32 135.8 32c36 0 70.5 14.3 96 39.8L256 96l24.2-24.2c0 0 0 0 0 0c25.5-25.4 60-39.7 96-39.7C451.2 32 512 92.8 512 167.8c0 36-14.3 70.5-39.8 96L448 288 289.9 446.1z"/></svg>)}
        </IconButton>
      </Box>
      <Box sx={styles.type}>
        {typeIcon}
      </Box>
    </Box>
	)
}

export default MediaThumbnail