import { createSlice } from '@reduxjs/toolkit'

const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {
    setLocationsRequested: (state) => {
      state.isLoading = true
      state.error = null
    },
    setLocationsSucceeded: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },
    setLocationsFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const { setLocationsRequested, setLocationsSucceeded, setLocationsFailed } = locationsSlice.actions
export default locationsSlice.reducer