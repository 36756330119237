import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import TagPlansDesktop from './TagPlansDesktop'
import TagPlansMobile from './TagPlansMobile'
import TagRetroactiveDrawer from './TagRetroactiveDrawer'

const TagStatusInactive = () => {
	const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				flex: 1,
				justifyContent: 'flex-end',
				maxWidth: 360,
			},
		},
	}

	const desktopMarkup = <TagPlansDesktop />
	const mobileMarkup = <TagPlansMobile />
	
	return (
		<Box sx={styles.wrapper}>
			{width > 992 ? desktopMarkup : mobileMarkup}
			<TagRetroactiveDrawer />
		</Box>
	)
}
 
export default TagStatusInactive