export const Accordion = {
  MuiAccordion: {
    styleOverrides: {
      root: {
				margin: 0,
				padding: 0,
				background: 'transparent',
				boxShadow: 'none',
      },
    },
  },
}

export const AccordionSummary = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
				margin: 0,
				padding: 0,
        '& > .MuiAccordionSummary-content': {
					margin: 0,
					padding: 0,
        },
      },
    },
  },
}

export const AccordionDetails = {
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
      },
    },
  },
}