import { createContext, useContext, useEffect, useState } from 'react'

const FlutterMessagesContext = createContext()

const useFlutterMessages = () => (useContext(FlutterMessagesContext))

const FlutterMessagesProvider = ({ children }) => {
  // const [showMenu, setShowMenu] = useState(false)
  const [isNativeMobile, setIsNativeMobile] = useState(false)

  useEffect(() => {
    const sendMessage = (msg) => {
      if (msg?.type === 'mobile') setIsNativeMobile(true)
    }

    window.sendMessage = sendMessage
  }, [])

  // const handleMobileAuth = (message) => {
  //   if (window?.authHandler) window.authHandler.postMessage(message)
  // }

  const value = {
    isNativeMobile,
    // showMenu,
    // setShowMenu,
    // handleMobileAuth
  }

  return (
    <FlutterMessagesContext.Provider value={value}>
      {children}
    </FlutterMessagesContext.Provider>
  )
}

export { useFlutterMessages }
export default FlutterMessagesProvider