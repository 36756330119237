import { useEffect } from 'react'

import { theme } from '../../themes/theme'

const LightboxVideoDisplay = ({ path, videoRef, isPlaying, handleTogglePlaying }) => {
	const styles = {
		video: {
			backgroundColor: theme.palette.background.darkest,
			objectFit: 'cover',
			width: '100%',
			height: '100%',
		},
	}

	useEffect(() => {
		const videoElement = videoRef.current
		if (videoElement) {
			videoElement.setAttribute('playsinline', 'true')
			videoElement.setAttribute('webkit-playsinline', 'true')
		}
	// eslint-disable-next-line
	}, [])

	// useEffect(() => {
	// 	const video = videoRef?.current
	// 	if (!video) return
		
	// 	if (isPlaying) video.play()
	// 	else video.pause()
	// }, [isPlaying, videoRef])

	return (
		<video
			ref={videoRef}
			style={styles.video}
			// onClick={handleTogglePlaying}
			width='100%'
			height='100%'
			autoPlay
			controls
			loop
			muted
			playsInline
		>
			<source src={path} type='video/mp4' />
		</video>
	)
}
 
export default LightboxVideoDisplay