import { doc, onSnapshot, collection, query, where } from 'firebase/firestore'
import { auth, db } from '../../firebase/initialize'
import { setUserRequested, setUserSucceeded, setUserFailed } from '../reducers/userSlice'
import { mixpanel } from '../../mixpanel/initialize'

// Utility function to debounce calls
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

export const listenForAuthChanges = () => async (dispatch) => {
  auth.onAuthStateChanged(debounce(async (user) => {
    dispatch(setUserRequested())
    
    try {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid)

        onSnapshot(
          userDocRef,
          (snapshot) => {
            try {
              const data = snapshot.data()
              dispatch(setUserSucceeded({ id: user.uid, ...data }))

              mixpanel.identify(user.uid)
              mixpanel.people.set({ $email: data.email })
            } catch (error) {
              console.error('Snapshot processing error:', error)
              dispatch(setUserFailed(error.message))
            }
          },
          (error) => {
            console.error('Snapshot listener error:', error)
            dispatch(setUserFailed(error.message))
          }
        )
      } else {
        dispatch(setUserSucceeded(null))
      }
    } catch (error) {
      console.error('Auth error:', error)
      dispatch(setUserFailed(error.message))
    }
  }, 250))
}

export const listenForSubscriptionChanges = (user) => async (dispatch) => {
  if (!user || !user.id) return

  const userId = user.id
  const subscriptionsColRef = collection(db, 'users', userId, 'subscriptions')
  const subscriptionsColQry = query(subscriptionsColRef, where('status', 'in', ['trialing', 'active']))

  onSnapshot(
    subscriptionsColQry,
    (snapshot) => {
      try {
        dispatch(setUserRequested())

        const activeSubscription = !snapshot.empty

        dispatch(setUserSucceeded({ ...user, active_subscription: activeSubscription }))
      } catch (error) {
        console.error('Snapshot processing error:', error)
        dispatch(setUserFailed(error.message))
      }
    },
    (error) => {
      console.error('Snapshot listener error:', error)
      dispatch(setUserFailed(error.message))
    }
  )
}