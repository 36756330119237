export const groupSessions = media => {
	if (!media?.length) return

	const sessionsArr = media.reduce((sessions, item) => {
		const date = item.date
		const existingSession = sessions.find(session => session.date === date)
		
		if (existingSession) {
			existingSession.media.push(item)

			if (!existingSession.locationNames) existingSession.locationNames = []
			if (!existingSession.locationNames.includes(item?.locationName)) {
				existingSession.locationNames.push(item?.locationName)
			}
		} else {
			sessions.push({ date, media: [item], locationNames: [item?.locationName] })
		}

		return sessions
	}, [])

	const sortedSessions = sessionsArr.sort((a, b) => {
		return new Date(b.date) - new Date(a.date)
	})

	return sortedSessions
}