import { doc, collection, updateDoc } from 'firebase/firestore'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LightboxAction from './LightboxAction'

const LightboxActionsCoverset = ({ media, index }) => {
	const { width } = useWindowDimensions()
	const isCoverIndex = media?.cover_index === index
	const mediaDocRef = doc(collection(db, 'media'), media?.id)
	const iconColor = width > 992 ? theme.palette.text.inverse : theme.palette.text.primary
	const defaultBookmarkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path fill={iconColor} d="M512 64V288H64V64H512zM64 0H0V64 288v64H64 512h64V288 64 0H512 64zM0 416v96H96V416H0zm256 0H160v96h96V416zm64 0v96h96V416H320zm256 0H480v96h96V416z"/></svg>
	const currentBookmarkIcon = (
		<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 19 16">
			<path fill={iconColor} d="M0.529541 0H2.52954H16.5295H18.5295V2V9V11H16.5295H2.52954H0.529541V9V2V0Z"/>
			<path fill={iconColor} d="M0.529541 16V13H3.52954V16H0.529541Z"/>
			<path fill={iconColor} d="M5.52954 13H8.52954V16H5.52954V13Z"/>
			<path fill={iconColor} d="M10.5295 16V13H13.5295V16H10.5295Z"/>
			<path fill={iconColor} d="M15.5295 13H18.5295V16H15.5295V13Z"/>
		</svg>
	)

	const handleCoverset = async () => await updateDoc(mediaDocRef, { cover_index: index })

	return (
		<LightboxAction
			action={isCoverIndex ? null : handleCoverset}
			icon={isCoverIndex ? currentBookmarkIcon : defaultBookmarkIcon}
			tooltip={isCoverIndex ? 'This is the cover shot' : 'Make this the cover shot'}
			label={isCoverIndex ? 'Cover shot' : 'Cover shot'}
			color='black'
			disabled={isCoverIndex}
			largeOnMobile
		/>
	)
}
 
export default LightboxActionsCoverset