import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { alpha, Box, Avatar, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { storage, db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import { doc, updateDoc } from '@firebase/firestore'

const AccountIdCard = () => {
  const user = useSelector(state => state.rootReducer.user.data)
  const timestamp = Date.now()
  const avatarPath = ref(storage, `avatars/${user?.id}/${timestamp}`)
  const [uploadStatus, setUploadStatus] = useState('default')
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: 2,
			paddingTop: 4,
			paddingLeft: 4,
			paddingRight: 4,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		avatar: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			width: 120,
			height: 120,
			objectFit: 'cover',
			borderRadius: '50%',
			overflow: 'hidden',
			backgroundColor: theme.palette.background.dark,
			'& span': {
				width: '100%',
				height: '100%',
			}
		},
		avatarImage: {
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 1,
			width: '100%',
			height: '100%',
		},
		upload: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 2,
			overflow: 'hidden',
			cursor: 'pointer',
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		uploadInput: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 2,
			opacity: 0,
		},
		uploadContent: {
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingBottom: 0.4,
			width: '100%',
			height: 24,
		},
		uploadDefault: {
			backgroundColor: alpha(theme.palette.background.darker, 0.5),
			opacity: 0.65,
			'&:hover': { opacity: 0.9, },
		},
		uploadSuccess: { backgroundColor: theme.palette.success.main, },
		uploadError: { backgroundColor: theme.palette.error.main, },
		uploadLoading: { backgroundColor: alpha(theme.palette.background.darker, 0.5), },
		uploadMessage: {
			position: 'relative',
			zIndex: 2,
			fontSize: 11,
			color: theme.palette.text.inverse,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
			[theme.breakpoints.down('md')]: { alignItems: 'center', },
		},
		username: {
			fontSize: 24,
			color: theme.palette.heading.secondary,
			lineHeight: 1,
			fontWeight: 600,
		},
	}

  const handleUpload = async (e) => {
    const file = e.target.files[0]
    const uploadTask = uploadBytesResumable(avatarPath, file)

    setUploadStatus('loading')
    uploadTask.on('state_changed', snapshot => {
        console.log(`${(snapshot.bytesTransferred / snapshot.totalBytes) * 100}%`)
      },
      error => {
        console.error(error)
        setUploadStatus('error')
        enqueueSnackbar(`Error uploading profile picture.`, { variant: 'error', })
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const userDocRef = doc(db, 'users', user.id)
          updateDoc(userDocRef, { avatar: downloadURL })
        })
        setUploadStatus('success')
        enqueueSnackbar(`Your profile picture has been updated!`, { variant: 'success', })
      }
    )
  }

  useEffect(() => {
    if (uploadStatus === 'success') {
      setTimeout(() => { setUploadStatus('default') }, 3000)
    }
  }, [uploadStatus])

	let uploadContent
  switch (uploadStatus) {
    case 'success':
      uploadContent = (
        <Box sx={{ ...styles.uploadContent, ...styles.uploadSuccess }}>
          <Typography sx={styles.uploadMessage}>Done!</Typography>
        </Box>
      )
      break
    case 'error':
      uploadContent = (
        <Box sx={{ ...styles.uploadContent, ...styles.uploadError }}>
          <Typography sx={styles.uploadMessage}>Hello</Typography>
        </Box>
      )
      break
    case 'loading':
      uploadContent = (
        <Box sx={{ ...styles.uploadContent, ...styles.uploadLoading }}>
          <Typography sx={styles.uploadMessage}>Loading...</Typography>
        </Box>
      )
      break
    default:
      uploadContent = (
        <>
          <input type='file' style={styles.uploadInput} onChange={handleUpload} />
          <Box sx={{ ...styles.uploadContent, ...styles.uploadDefault }}>
            <Typography sx={styles.uploadMessage}>Upload</Typography>
          </Box>
        </>
      )
  }
  
  return (
    <Box sx={styles.wrapper}>
      <Avatar sx={styles.avatar}>
        {user?.avatar
					? <LazyLoadImage style={styles.avatarImage} src={user.avatar} effect='blur' alt={`${user?.firstName} ${user?.lastName}`} />
					: <FontAwesomeIcon size='xs' icon={icon({ name: 'user', family: 'sharp', style: 'solid' })} />}
        <Box sx={styles.upload}>{uploadContent}</Box>
      </Avatar>
      <Box sx={styles.content}>
        <Typography sx={styles.username}>{`@${user?.username}`}</Typography>
        <Typography sx={{ lineHeight: 1 }}>{`${user?.firstName} ${user?.lastName}`}</Typography>
      </Box>
    </Box>
  )
}

export default AccountIdCard