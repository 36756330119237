import { Link } from 'react-router-dom'
import { MenuList, Box } from '@mui/material'

import { theme } from '../../../themes/theme'
import logo from '../../../assets/images/logo.svg'
import NavMajor from './NavMajor'
import NavMinorList from './NavMinorList'

const Nav = () => {
	const styles = {
		wrapper: {
			position: 'fixed',
			top: 0,
			left: 0,
			bottom: 0,
			zIndex: 10000,
			display: 'flex',
			flexDirection: 'column',
			width: 280,
			height: '100vh',
			paddingTop: 0,
			paddingLeft: 2,
			paddingRight: 0,
			paddingBottom: 0,
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.down('md')]: {
				top: 'auto',
				right: 0,
				flexDirection: 'row',
				width: '100vw',
				height: 80,
				paddingTop: 0,
				paddingLeft: 1,
				paddingRight: 1,
				paddingBottom: 0,
				borderTop: `1px solid ${theme.palette.divider}`,
				backgroundColor: theme.palette.background.paper,
			}
		},
		head: {
			display: 'flex',
			alignItems: 'center',
			height: 70,
			paddingLeft: 2,
			paddingRight: 2,
			[theme.breakpoints.down('md')]: { display: 'none', },
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			padding: 0,
			[theme.breakpoints.down('md')]: {
				display: 'grid',
				gridTemplateColumns: 'repeat(5, 1fr)',
				width: '100%',
			}
		},
		foot: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			paddingBottom: 3,
			[theme.breakpoints.down('md')]: { display: 'none', },
		},
	}
	
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.head}>
        <Link to='/' children={(<img src={logo} alt='Hightag' style={{ width: 136, }} />)} />          
      </Box>
      <MenuList sx={styles.body}>
				<NavMajor label='Home' to='/' icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" className='nav-icon'><path fill='currentColor' d="M10.1 0L20.1 9.5V11H18.1V20H12.6V14H7.6V20H2.1V11H0.0999985V9.5L10.1 0Z"/></svg>} />
				<NavMajor label='Media' to='/media?tab=all' icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" className='nav-icon'><path fill='currentColor' d="M4.8 0.5H15.8V2H4.8V0.5ZM2.3 3H18.3V5H2.3V3Z"/><path fill='currentColor' fillRule="evenodd" clipRule="evenodd" d="M0.299995 6H20.3V19.5H0.299995V6ZM7.8 11L12.3 15.5L14.8 13L17.8 16V17H2.8V16L7.8 11Z"/></svg>} />
				<NavMajor label='My Tag' to='/tag' icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" className='nav-icon small'><path fill='currentColor' d="M0.5 14.818V18H3.68199L14 7.682V18H18.5V0H15.318L0.5 14.818Z"/><path fill='currentColor' d="M7.9896 4.5H0.5V0H12.4896L7.9896 4.5Z"/></svg>} />
				<NavMajor label='Locations' to='/locations' icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20" className='nav-icon'><path fill='currentColor' fillRule="evenodd" clipRule="evenodd" d="M14.4893 13.5212C15.7036 12 16.7 10.1409 16.7 8C16.7 3.5817 13.1183 0 8.69998 0C4.28168 0 0.699982 3.5817 0.699982 8C0.699982 10.1409 1.69788 12 2.91058 13.5212L8.69998 20L14.4893 13.5212ZM8.69998 10.5C10.0807 10.5 11.2 9.3807 11.2 8C11.2 6.6193 10.0807 5.5 8.69998 5.5C7.31928 5.5 6.19998 6.6193 6.19998 8C6.19998 9.3807 7.31928 10.5 8.69998 10.5Z"/></svg>} />
				<NavMajor label='Account' to='/account' icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" className='nav-icon small'><path fill='currentColor' d="M8.90001 10C10.2337 10 11.5127 9.4732 12.4557 8.5355C13.3988 7.59785 13.9286 6.32608 13.9286 5C13.9286 3.67392 13.3988 2.40215 12.4557 1.46447C11.5127 0.52678 10.2337 0 8.90001 0C7.56631 0 6.28731 0.52678 5.34427 1.46447C4.40123 2.40215 3.87144 3.67392 3.87144 5C3.87144 6.32608 4.40123 7.59785 5.34427 8.5355C6.28731 9.4732 7.56631 10 8.90001 10ZM17.7 20L15.1857 11.875H2.6143L0.100006 20H17.7Z"/></svg>} />
      </MenuList>
      <Box sx={styles.foot}>
				<NavMinorList />
      </Box>
    </Box>
  )
}

export default Nav