import { useRef } from 'react'
// import { useRef, useState } from 'react'
import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import LightboxMainHead from './LightboxMainHead'
import LightboxMainBody from './LightboxMainBody'
import LightboxMainFoot from './LightboxMainFoot'

const LightboxMain = ({ handleCloseModal, media, index, setIndex }) => {
	// const [isPlaying, setIsPlaying] = useState(true)
	// const [progress, setProgress] = useState(false)
	const videoRef = useRef(null)
	const styles = {
		wrapper: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.darkest,
			[theme.breakpoints.down('md')]: {
				flexGrow: 0,
				backgroundColor: theme.palette.background.default,
			},
		},
	}

	// const handleTogglePlaying = () => setIsPlaying(prev => !prev)
	
  return (
    <Box sx={styles.wrapper}>
      <LightboxMainHead
				media={media}
				index={index}
				handleCloseModal={handleCloseModal}
			/>
      <LightboxMainBody
				media={media}
				index={index}
				videoRef={videoRef}
				// isPlaying={isPlaying}
				// handleTogglePlaying={handleTogglePlaying}
				// progress={progress}
			/>
      <LightboxMainFoot
				media={media}
				index={index}
				setIndex={setIndex}
				videoRef={videoRef}
				// isPlaying={isPlaying}
				// handleTogglePlaying={handleTogglePlaying}
				// progress={progress}
				// setProgress={setProgress}
			/>
    </Box>
  )
}

export default LightboxMain