import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { groupSessions } from '../../utilities/groupSessions'
import MediaSessionsItem from './MediaSessionsItem'

const MediaSessions = ({ media, onSessionClick }) => {
	const sessions = groupSessions(media)
	const styles = {
		wrapper: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
			gap: 1.5,
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
				// rowGap: 0,
			},
		},
	}

  return (
    <Box sx={styles.wrapper}>
      {sessions?.map((session, index) => (
        <MediaSessionsItem session={session} key={index} onSessionClick={onSessionClick} />
      ))}
    </Box>
  )
}

export default MediaSessions