import { useState } from 'react'
import { doc, updateDoc } from '@firebase/firestore'
import { Modal, Fade, Box } from '@mui/material'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import LightboxMain from './LightboxMain'
import LightboxAside from './LightboxAside'

const Lightbox = ({ open, setOpen, media, initialIndex }) => {
  const [index, setIndex] = useState(initialIndex)
  const [updateFavorite, setUpdateFavorite] = useState(null)
  const mediaDocRef = doc(db, 'media', media?.id)
	const styles = {
		wrapper: {
			display: 'flex',
			width: '100%',
			height: '100%',
			backgroundColor: theme.palette.background.default,
			overflowY: 'auto',
			[theme.breakpoints.down('md')]: { flexDirection: 'column', },
		},
	}

  const decrementIndex = () => {
		if (index > 0) setIndex(prev => prev - 1)
		else setIndex(media?.path?.length - 1)
	}

	const incrementIndex = () => {
		if (index < (media?.path?.length - 1)) setIndex(prev => prev + 1)
		else setIndex(0)
	}

  const handleKeyDown = e => {
    if (e.key === 'Escape') handleCloseModal()
    if (e.key === 'ArrowLeft') decrementIndex()
    if (e.key === 'ArrowRight') incrementIndex()
  }
  
  const handleCloseModal = async () => {
    setOpen(false)
		if (updateFavorite !== null) await updateDoc(mediaDocRef, { favorite: updateFavorite })
    setUpdateFavorite(null)
  }
  
  return (
    <Modal open={open} onKeyDown={handleKeyDown} hideBackdrop>
      <Fade in={open}>
        <div>
          <Box sx={styles.wrapper}>
            <LightboxMain
							media={media}
							index={index}
							handleCloseModal={handleCloseModal}
							setIndex={setIndex}
						/>
            <LightboxAside
							media={media}
							index={index}
							handleCloseModal={handleCloseModal}
							setUpdateFavorite={setUpdateFavorite}
						/>
          </Box>
        </div>
      </Fade>
    </Modal>
  )
}

export default Lightbox