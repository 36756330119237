import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Confetti from 'react-confetti'

import { theme } from '../../themes/theme'
import TagGraphic from './TagGraphic'
import TagAlias from './TagAlias'
import TagMessage from './TagMessage'
import TagStatusUnlinked from './TagStatusUnlinked'
import TagStatusInactive from './TagStatusInactive'
import TagStatusActive from './TagStatusActive'

const Tag = () => {
  const tags = useSelector(state => state.rootReducer.tags.data)
  const user = useSelector(state => state.rootReducer.user.data)
	const [searchParams] = useSearchParams()
	const status = searchParams.get('status')
  const tagLinked = tags?.length > 0
	const activeStatuses = ['active', 'trialing', 'incomplete']
  const accountActive = activeStatuses.includes(user?.activation_status)
	const confetti = <Confetti recycle={false} numberOfPieces={600} tweenDuration={15000} />
	const styles = {
		wrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'center',
			gap: 6,
			paddingTop: 13,
			paddingLeft: 2.5,
			paddingRight: 3,
			paddingBottom: 4,
			width: '100%',
			[theme.breakpoints.down('md')]: {
				paddingTop: 8,
				paddingLeft: 2.5,
				paddingRight: 2.5,
				paddingBottom: 14,
				minHeight: '100svh',
			},
			[theme.breakpoints.down('sm')]: {
				paddingTop: '66px', // Same as on Account page
				paddingLeft: 2,
				paddingRight: 2,
			},
		},
		head: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
		},
		body: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			// [theme.breakpoints.down('sm')]: { alignItems: 'stretch', },
		},
	}

	useEffect(() => {
		if (status === 'succeeded') enqueueSnackbar('Your account is active!', { variant: 'success', })
	}, [status])

  let statusMarkup = <TagStatusUnlinked />
	if (tagLinked && !accountActive) statusMarkup = <TagStatusInactive />
	if (tagLinked && accountActive) statusMarkup = <TagStatusActive />

  return (
    <Box sx={styles.wrapper}>
			{status === 'succeeded' && confetti}
			<Box sx={styles.head}>
				<TagGraphic />
				<TagAlias />
				<TagMessage />
			</Box>
			<Box sx={styles.body}>
      	{statusMarkup}
			</Box>
    </Box>
  )
}

export default Tag