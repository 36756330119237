import { useEffect, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import MediaMain from './MediaMain'
import MediaStatusView from '../_common/MediaStatusView'
import MediaTabs from './MediaTabs'

const Media = () => {
	const user = useSelector(state => state.rootReducer.user.data)
  const media = useSelector(state => state.rootReducer.media.data)
	const inactiveMedia = useSelector(state => state.rootReducer.inactiveMedia.data)
	const location = useLocation()
  const [scrollTo, setScrollTo] = useState(location.state?.scrollTo || null)
	const [searchParams, setSearchParams] = useSearchParams()
	const styles = {
		wrapper: {
			flex: 1,
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			paddingTop: 8.75,
			paddingLeft: 2.5,
			paddingRight: 3,
			paddingBottom: 3,
			minHeight: '100svh',
			[theme.breakpoints.up('md')]: { paddingTop: !media?.length ? 3 : 8.75, },
			[theme.breakpoints.down('md')]: {
				paddingLeft: 2.5,
				paddingRight: 2.5,
				paddingBottom: 12.5,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 2,
				paddingRight: 2,
				height: 'auto',
			},
		},
	}

	useEffect(() => {
		if (!searchParams.has('tab')) setSearchParams({ tab: 'all' })
	}, [searchParams, setSearchParams])

  const onSessionClick = (scrollPosition) => {
    setSearchParams({ tab: 'all' })
    setScrollTo(scrollPosition)
  }

	let mediaMarkup = <MediaStatusView status='empty' />

	if (media?.length > 0) mediaMarkup = <MediaMain media={media} scrollTo={scrollTo} onSessionClick={onSessionClick} />
	if (!media?.length && inactiveMedia?.length > 0 && !user?.hasBeenActivated) mediaMarkup = <MediaStatusView status='retroactive' />

  return (
		<Box sx={styles.wrapper} id='Media'>
			<MediaTabs disabled={!media?.length} />
			{mediaMarkup}
		</Box>
  )
}

export default Media