import { alpha, Box, Stack, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import freeBegIcon from '../../assets/images/Freeride-Beginner.svg'
import freeIntIcon from '../../assets/images/Freeride-Intermediate.svg'
import freeAdvIcon from '../../assets/images/Freeride-Advanced.svg'
import freeExpIcon from '../../assets/images/Freeride-Expert.svg'
import freeProIcon from '../../assets/images/Freeride-Pro.svg'
import techBegIcon from '../../assets/images/Technical-Beginner.svg'
import techIntIcon from '../../assets/images/Technical-Intermediate.svg'
import techAdvIcon from '../../assets/images/Technical-Advanced.svg'
import techExpIcon from '../../assets/images/Technical-Expert.svg'
import techProIcon from '../../assets/images/Technical-Pro.svg'

const LocationsListItemTrails = ({ location }) => {
	const trails = location?.trails
	const styles = {
		wrapper: {
			paddingTop: 1.5,
			[theme.breakpoints.down('sm')]: {
				padding: 1,
				paddingTop: 0,
				paddingBottom: 2,
			},
		},
		trail: {
			borderTop: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
			paddingTop: '5px',
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 1,
				paddingRight: 1,
			},
		},
		indicator: {
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: theme.palette.grey[500],
		},
		indicatorActive: { backgroundColor: theme.palette.success.main, },
	}

	const trailsMarkup = trails?.map(trail => {
		const type = trail?.type
		const difficulty = trail?.difficulty
		const devices = trail?.devices
		const devicesCount = devices?.length
		const activeDevices	= devices?.filter(device => device?.status === 'online')?.length || 0
		let trailIcon = <></>

		if (type === 'Freeride' && difficulty === 'Beginner') trailIcon = freeBegIcon
		if (type === 'Freeride' && difficulty === 'Intermediate') trailIcon = freeIntIcon
		if (type === 'Freeride' && difficulty === 'Advanced') trailIcon = freeAdvIcon
		if (type === 'Freeride' && difficulty === 'Expert') trailIcon = freeExpIcon
		if (type === 'Freeride' && difficulty === 'Pro') trailIcon = freeProIcon
		if (type === 'Technical' && difficulty === 'Beginner') trailIcon = techBegIcon
		if (type === 'Technical' && difficulty === 'Intermediate') trailIcon = techIntIcon
		if (type === 'Technical' && difficulty === 'Advanced') trailIcon = techAdvIcon
		if (type === 'Technical' && difficulty === 'Expert') trailIcon = techExpIcon
		if (type === 'Technical' && difficulty === 'Pro') trailIcon = techProIcon

		return (
			<Stack sx={styles.trail} direction='row' justifyContent='space-between' alignItems='center' key={trail?.id}>
				<Stack direction='row' alignItems='center' gap={1}>
					<img style={{ height: 20, }} src={trailIcon} alt='Freeride - Intermediate' />
					<Typography variant='body3' children={trail?.name} />
				</Stack>
				<Stack sx={{ width: 88, }} direction='row' alignItems='center' gap={0.75}>
					<Box sx={activeDevices > 0 ? { ...styles.indicator, ...styles.indicatorActive} : styles.indicator} />
					<Typography variant='body3' color={theme.palette.text.primary} children={`${activeDevices} / ${devicesCount} online`} />
				</Stack>
			</Stack>
		)
	})

	return <Stack sx={styles.wrapper} gap={'5px'}>{trailsMarkup}</Stack>
}
 
export default LocationsListItemTrails