import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import MediaThumbnail from '../_common/MediaThumbnail'
import MediaFavoritesEmpty from './MediaFavoritesEmpty'

const MediaFavorites = ({ media }) => {
	const favoriteMedia = media?.filter(mediaItem => mediaItem?.favorite === true)
	const favoritesExist = !!favoriteMedia?.length
	const styles = {
		wrapper: {
			position: 'relative',
			zIndex: 1,
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
			gap: 0.25,
			borderRadius: 5,
			marginTop: 3.5,
			overflow: 'hidden',
			backgroundColor: theme.palette.background.shaded,
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
				marginLeft: favoritesExist ? -1 : 0,
				marginRight: favoritesExist ? -1 : 0,
			},
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
				marginLeft: favoritesExist ? -1.75 : 0,
				marginRight: favoritesExist ? -1.75 : 0,
			},
		},
		corners: {
			position: 'sticky',
			top: 70,
			zIndex: 2,
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: -3.5,
			height: 0,
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.down('md')]: {
				marginLeft: -1,
				marginRight: -1,
				height: 12,
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: -1.75,
				marginRight: -1.75,
			},
		},
		corner: {
			position: 'absolute',
			top: '100%',
			height: 20,
			aspectRatio: '1 / 1',
		},
	}

  let markup = <MediaFavoritesEmpty />
	if (favoritesExist) {
		markup = (favoriteMedia?.sort((a, b) => (b.datetime - a.datetime)).map((mediaItem, index) => (
			<MediaThumbnail mediaItem={mediaItem} key={index} />
		)))
	}

  return (
    <>
      <Box sx={styles.corners}>
        <Box sx={{ ...styles.corner, left: 0, }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60" fill="none"><path fill={theme.palette.background.default} d="M60 0H0V60C0 26.8628 26.8628 0 60 0Z" /></svg>
        </Box>
        <Box sx={{ ...styles.corner, right: 0, }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60" fill="none"><path fill={theme.palette.background.default} d="M0 0H60V60C60 26.8628 33.1372 0 0 0Z" /></svg>
        </Box>
      </Box>
      <Box sx={styles.wrapper}>
        {markup}
      </Box>
    </>
  )
}

export default MediaFavorites