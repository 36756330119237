import { createSlice } from '@reduxjs/toolkit'

const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {
    setTagsRequested: (state) => {
      state.isLoading = true
      state.error = null
    },
    setTagsSucceeded: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.error = null
    },
    setTagsFailed: (state, action) => {
      state.isLoading = false
      state.data = null
      state.error = action.payload
    },
  },
})

export const { setTagsRequested, setTagsSucceeded, setTagsFailed } = tagsSlice.actions
export default tagsSlice.reducer