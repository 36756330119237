import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const MediaFavoritesEmpty = () => {
	const styles = {
		wrapper: {
			gridColumn: '1 / -1',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: 3,
			width: '100%',
			height: 'calc(100svh - 72px - 40px)',
			borderRadius: 3,
			backgroundColor: theme.palette.background.shaded,
			[theme.breakpoints.down('md')]: { height: 'calc(100svh - 72px - 80px - 24px)', },
		},
	}

	return (
		<Box sx={styles.wrapper}>
			<Typography color={theme.palette.text.secondary} variant='body2' children="You haven't favorited any photos yet" />
		</Box>
	)
}
 
export default MediaFavoritesEmpty