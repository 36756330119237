import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography, Dialog, IconButton,  } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import Popup from '../_common/Popup'

const AccountDelete = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
			<Button variant='outlined' color='inherit' size='large' onClick={() => setOpen(true)} disableRipple>
				<Typography variant='button1'>Delete my account</Typography>
			</Button>
      <Dialog open={open} onClick={() => setOpen(false)} maxWidth='xs'>
        <Popup
          title='Delete account'
          closer={(
            <IconButton color='secondary' onClick={() => setOpen(false)}>
              <FontAwesomeIcon size='sm' icon={icon({name: 'xmark', family: 'sharp', style: 'solid'})} />
            </IconButton>
          )}
          actions={(
            <>
              <Button
                variant='contained'
                color='secondary'
								size='small'
                component={Link}
                to='https://www.hightag.com/contact/support'
								disableRipple
							>
								<Typography variant='button2' children='Contact support'/>
							</Button>
              <Button variant='outlined' color='inherit' size='small' onClick={() => setOpen(false)} disableRipple>
								<Typography variant='button2' children='Cancel'/>
							</Button>
            </>
          )}
          children={(
            <Typography>
              If you wish to delete your account, along with associated personal data,
              submit a support request and our team will remove your account within
              7 business days. Please note, deleting your account and data cannot be undone.
            </Typography>
          )}
        />
      </Dialog>
    </>
  )
}

export default AccountDelete