const LightboxImageDisplay = ({ path, setImageLoaded }) => {
	const styles = {
		image: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
	}

	return (
		<>
			<img
				style={{ ...styles.image, zIndex: 3 }}
				src={path}
				key={path}
				onLoad={() => setImageLoaded(true)}
				alt='Hightag action shot'
			/>
			<img
				style={{ ...styles.image, zIndex: 2, opacity: 0.3, }}
				src={path}
				alt='Hightag action shot'
			/>
		</>
	)
}
 
export default LightboxImageDisplay