import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import MediaStatusView from '../_common/MediaStatusView'
import HomeHead from './HomeHead'
import HomeMedia from './HomeMedia'

const Home = () => {
	const user = useSelector(state => state.rootReducer.user.data)
	const media = useSelector(state => state.rootReducer.media.data)
	const inactiveMedia = useSelector(state => state.rootReducer.inactiveMedia.data)
	const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
			paddingTop: media?.length ? 0 : 3,
			paddingLeft: 2.5,
			paddingRight: 3,
			paddingBottom: 3,
			minHeight: '100svh',
			[theme.breakpoints.down('md')]: {
				paddingTop: 8.75,
				paddingLeft: 2.5,
				paddingRight: 2.5,
				paddingBottom: 12.5,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 2,
				paddingRight: 2,
				height: 'auto',
			},
		},
	}

	let markup = <MediaStatusView status='empty' />

	if (media?.length > 0) markup = <HomeMedia />
	if (!media?.length && inactiveMedia?.length > 0 && !user?.hasBeenActivated) markup = <MediaStatusView status='retroactive' />

	return (
		<Box sx={styles.wrapper}>
			{width < 992 && <HomeHead />}
			{markup}
		</Box>
	)
}

export default Home