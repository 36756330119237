import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LightboxInfo from './LightboxInfo'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  date: {
    fontSize: 15,
    lineHeight: 1.3,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  time: {
    fontSize: 13,
    lineHeight: 1.3,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}

const LightboxInfoTime = ({ media }) => {
  const { width } = useWindowDimensions()
  const date = dayjs(media?.datetime).format('MMMM D, YYYY')
  const time = dayjs(media?.datetime).format('h:mm:ss A')

  let markup = (
    <LightboxInfo
        major={date}
        minor={time}
        icon={<svg xmlns="http://www.w3.org/2000/svg" width="0.9em" viewBox="0 0 512 512"><path fill={theme.palette.text.primary} d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>}
    />
  )

  if (width < 992) markup = (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.date} variant='body2'>{date}</Typography>
      <Typography sx={styles.time} variant='body4'>{time}</Typography>
    </Box>
  )

  return markup
}
 
export default LightboxInfoTime