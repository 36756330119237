import { useRef, useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { auth } from '../../firebase/initialize'
import { decode } from '../../utilities/errorDecoding'
import Snackbar from '../_common/Snackbar'
import { styles } from './formStyles'

const LandingLogin = ({ setMode }) => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  })

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      await signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
    } catch (error) {
      console.error(error)
      setSnackbar({ open: true, type: 'error', message: decode(error.code) })
      setLoading(false)
    }
  }

  return (
    <Box sx={styles.formWrapper}>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
      <Typography variant='body3' sx={styles.message}>Enter your account information to login.</Typography>
      <Box sx={{ ...styles.form, maxWidth: 640, }}>
        <input className='form__textfield' type='email' ref={emailRef} placeholder='Email address' required />
        <input className='form__textfield' type='password' ref={passwordRef} placeholder='Password' required />
        <Box sx={styles.submit}>
          <Button variant='contained' color='primary' size='large' onClick={handleLogin} disabled={loading} disableRipple>
            {loading ?
              <CircularProgress /> :
              <Typography variant='button1'>Login</Typography>}
          </Button>
        </Box>
      </Box>
      <Typography variant='body3' sx={styles.switchLink} onClick={() => setMode('reset')}>Forgot your password?</Typography>
    </Box>
  )
}

export default LandingLogin