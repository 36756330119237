import { Box } from '@mui/material'

import { theme } from '../../themes/theme'

const Section = ({ heading, actions, children, headHeight, headStick, headPad }) => {
	const styles = {
		wrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},
		head: {
			position: headStick ? 'sticky' : 'relative',
			top: headStick || 'auto',
			zIndex: 2,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingTop: headPad || 0,
			height: headHeight || 'auto',
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.down('md')]: {
				marginLeft: -2.5,
				marginRight: -2.5,
				paddingLeft: 2.5,
				paddingRight: 2.5,
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: -2,
				marginRight: -2,
				paddingLeft: 2,
				paddingRight: 2,
			},
			'& > div': {
				display: 'flex',
				alignItems: 'center',
				gap: 1,
			},
		},
		body: {
			position: 'relative',
			zIndex: 1,
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},
	}

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.head}>
        <Box>{heading}</Box>
        <Box>{actions}</Box>
      </Box>
      <Box sx={styles.body}>
        {children}
      </Box>
    </Box>
  )
}

export default Section