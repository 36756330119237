import { useState } from 'react'
import { useSelector } from 'react-redux'
import { httpsCallable } from 'firebase/functions'
import { Button, Typography, CircularProgress } from '@mui/material'

import { functions } from '../../firebase/initialize'

const TagCheckout = ({ plans, selectedIndex }) => {
	const user = useSelector(state => state.rootReducer.user.data)
  const stripeCreateCheckout = httpsCallable(functions, 'stripeCreateCheckout')
	const [loading, setLoading] = useState(false)
	const trialed = !!user?.trialed
	const selectedPlan = plans[selectedIndex]
	let buttonText
	let message
	
	switch (true) {
		case !trialed && selectedPlan.title === 'Yearly Membership':
			buttonText = 'Start for free'
			message = '1 day free, then $69.99 per year'
			break
		case !trialed && selectedPlan.title === 'Monthly Membership':
			buttonText = 'Start for free'
			message = '1 day free, then $11.99 per month'
			break
		default:
			buttonText = 'Get started'
			message = 'Your activation period will begin after checkout'
	}

	const handleClick = async () => {
		setLoading(true)
		const session = await stripeCreateCheckout({
			userId: user.id,
			customerId: user?.stripeId,
			priceId: selectedPlan.priceId,
			successUrl: `${window.location.origin}/tag?status=succeeded`,
			cancelUrl: `${window.location.origin}/tag?status=cancelled`,
			mode: 'subscription',
			includeTrial: selectedPlan.title !== 'Day Pass' && !trialed,
		})
		if (session?.data?.url) window.location.href = session.data.url
		if (session?.data?.error) console.error(session.data.error)
		setLoading(false)
	}

	return (
		<>
			<Button
				fullWidth
				variant='contained'
				color='primary'
				size='large'
				sx={{ marginTop: 1.5, marginBottom: 1.5, }}
				onClick={handleClick}
				disabled={loading}
				children={loading ? <CircularProgress size={24} color='secondary' /> : buttonText}
				disableRipple
			/>
			<Typography variant='body3' sx={{ textAlign: 'center' }} children={message}/>
		</>
	)
}
 
export default TagCheckout