import { useSelector } from 'react-redux'
import { Button, Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const TagPlansOption = ({ plan, plans, selectedIndex, setSelectedIndex, handleClosePopover }) => {
	const user = useSelector(state => state.rootReducer.user.data)
	const trialed = !!user?.trialed
	const isDayPass = plan.title === 'Day Pass'
	const isSelected = selectedIndex === plans.indexOf(plan)
	const selectedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><path fill={theme.palette.primary.main} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM241 337l-17 17-17-17-80-80L161 223l63 63L351 159 385 193 241 337z"/></svg>
	const defaultIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><path fill={theme.palette.grey[500]} d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			paddingLeft: 1.5,
			paddingRight: 1.5,
			height: 64,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: isSelected ? theme.palette.primary.main : 'transparent',
			backgroundColor: isSelected ? theme.palette.primary.light : theme.palette.background.paper,
			'&:hover': { backgroundColor: theme.palette.primary.light },
		},
		main: {
			display: 'flex',
			alignItems: 'center',
			gap: 1.5,
		},
		details: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		trial: {
			position: 'absolute',
			top: 'calc(50% - 10px)',
			right: 8,
			display: 'flex',
			alignItems: 'center',
			paddingLeft: 0.8,
			paddingRight: 0.8,
			borderRadius: 1,
			height: 20,
			fontSize: 10,
			fontWeight: 600,
			letterSpacing: 0.3,
			textTransform: 'uppercase',
			color: isSelected ? theme.palette.text.inverse : theme.palette.text.primary,
			backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.divider,
		},
	}

	const handleClick = () => {
		setSelectedIndex(plans.indexOf(plan))
		setTimeout(() => handleClosePopover(), 250)
	}

	return (
		<Button sx={styles.wrapper} variant='text' color='secondary' onClick={handleClick} disableRipple>
			<Box sx={styles.main}>
				{isSelected ? selectedIcon : defaultIcon}
				<Box sx={styles.details}>
					<Typography variant='subtitle3' sx={{ color: isSelected && theme.palette.primary.dark }} children={plan.title} />
					<Typography variant='body3' children={plan.price} />
				</Box>
			</Box>
			{!isDayPass && !trialed && <Typography sx={styles.trial} children='Free trial' />}
		</Button>
	)
}
 
export default TagPlansOption