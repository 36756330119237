import { useState } from 'react'
import { ref, getBlob, getDownloadURL } from 'firebase/storage'
import { useSnackbar } from 'notistack'

import { storage } from '../../firebase/initialize'
import { useFlutterMessages } from '../../contexts/FlutterMessagesContext'
import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LightboxAction from './LightboxAction'

const LightboxActionsShare = ({ media, index }) => {
	const [isDownloading, setIsDownloading] = useState(false)
  const { isNativeMobile } = useFlutterMessages()
  const { enqueueSnackbar } = useSnackbar()
  const { width } = useWindowDimensions()
	const fileExt = media?.fileType === 'video' ? 'mp4' : 'jpg'
	const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill={theme.palette.text.primary} d="M241 345l-17 17-17-17L79 217l-17-17L96 166.1l17 17 87 87V24 0h48V24 270.1l87-87 17-17L385.9 200l-17 17L241 345zM48 344V464H400V344 320h48v24V488v24H424 24 0V488 344 320H48v24z"/></svg>
	const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill={theme.palette.text.primary} d="M0 288l55.6 23.8L192 370.3V376v64 56l48 16 73.3-89.7 88.9 38.1L448 480l6.6-49.4L504.1 59.5 512 0 459.6 29.4 52.7 258.4 0 288zM408.8 411L268.4 350.8 444.9 140.2 408.8 411zM222.3 331.1L108.3 282.2 401.5 117.2 222.3 331.1z"/></svg>

	const handleDownload = async () => {
    if (isDownloading) return
    setIsDownloading(true)

    const downloadRef = ref(storage, media?.path[index])

    try {
      if (isNativeMobile) {
        const url = await getDownloadURL(downloadRef)
        window.CHANNEL.postMessage(url)
      } else {
        const blob = await getBlob(downloadRef)
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `hightag_media.${fileExt}`
        a.click()
      }
    } catch (error) {
      console.error(error)
      return enqueueSnackbar('Something went wrong', { variant: 'error' })
    }

    setIsDownloading(false)
	}

	return (
		<LightboxAction
			action={handleDownload}
			icon={width > 992 ? downloadIcon : shareIcon}
			label={width > 992 ? 'Download' : 'Share'}
			color='secondary'
			largeOnMobile
		/>
	)
}
 
export default LightboxActionsShare