import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LightboxAsideActions from './LightboxAsideActions'
import LightboxInfoTime from './LightboxInfoTime'
import LightboxInfoLocation from './LightboxInfoLocation'
// import LightboxSponsor from './LightboxSponsor'

const LightboxAside = ({ media, index, handleCloseModal, setUpdateFavorite }) => {
  const { width } = useWindowDimensions()
	// const capturePointId = media?.capturePoint_id
	const styles = {
		wrapper: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: 2.5,
			paddingRight: 3,
			width: '25%',
			minWidth: '400px',
			[theme.breakpoints.down('md')]: {
				flex: 1,
				flexDirection: 'column-reverse',
				justifyContent: 'space-between',
				gap: 2,
				paddingTop: 1,
				paddingRight: 2.5,
				paddingBottom: 5,
				width: '100%',
				minWidth: 0,
			},
		},
		info: {
			position: 'static',
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
		},
		divider: {
			width: '100%',
			borderTop: `1px solid ${theme.palette.divider}`,
		},
	}

	console.log(media)
  
  return (
    <Box sx={styles.wrapper}>
      <LightboxAsideActions
				media={media}
				index={index}
				handleCloseModal={handleCloseModal}
				setUpdateFavorite={setUpdateFavorite}
			/>
			{width > 992 && <Box sx={styles.divider}></Box>}
			{width > 992 && <LightboxInfoTime media={media} />}
			{width > 992 && <Box sx={styles.divider}></Box>}
			<LightboxInfoLocation media={media} />
			{/* <LightboxSponsor capturePointId={capturePointId} /> */}
    </Box>
  )
}
 
export default LightboxAside