import { Button } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

import TextInput from '../_common/TextInput'
import SetupForm from './SetupForm'

const SetupProfile = ({ values, setValues, setActiveStep }) => {
  const handleNext = () => {
    let errors = []

    if (!values?.firstName) errors.push({ message: `First name can't be empty.` })
    if (!/^(?!.*([a-zA-Z- '])\1\1)[a-zA-Z- 'àáâãäåæçèéêëìíîïðòóôõöøùúûüýþÿ]{1,256}$/i.test(values?.firstName) && values?.firstName) errors.push({ message: `First name can't have numbers or special characters.` })
    if (!values?.lastName) errors.push({ message: `Last name can't be empty.` })
    if (!/^(?!.*([a-zA-Z- '])\1\1)[a-zA-Z- 'àáâãäåæçèéêëìíîïðòóôõöøùúûüýþÿ]{1,256}$/i.test(values?.lastName) && values?.lastName) errors.push({ message: `Last name can't have numbers or special characters.` })
    if (!values?.zip) errors.push({ message: `You must enter a valid 5-digit ZIP code.` })
    if (!/^\d{5}(?:-\d{4})?$/.test(values?.zip) && values?.zip) errors.push({ message: `You must enter a valid 5-digit ZIP code.` })
    if (values?.phone && !/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(values?.phone)) errors.push({ message: `Invalid phone number.` })

    if (errors?.length > 0) {
      errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      return errors = []
    }

    setActiveStep(prevState => prevState + 1)
  }

  return (
    <SetupForm
      title='My profile'
      message="Great! Let's build out your profile."
      children={(
        <>
          <TextInput
            name='firstName'
            label='First name *' 
            span={6}
            values={values}
            onChange={e => setValues(prevState => ({ ...prevState, firstName: e.target.value, }))}
            required
          />
          <TextInput
            name='lastName'
            label='Last name *'
            span={6}
            values={values}
            onChange={e => setValues(prevState => ({ ...prevState, lastName: e.target.value, }))}
            required
          />
          <TextInput
            name='zip'
            label='Home ZIP code *'
            span={3}
            values={values}
            required
            onChange={e => setValues(prevState => ({ ...prevState, zip: e.target.value, }))}
          />
          <TextInput
            name='dob'
            label='Date of birth *'
            type='date'
            span={3}
            values={values}
            required
            onChange={e => setValues(prevState => ({ ...prevState, dob: e.target.value, }))}
          />
          <TextInput
            name='phone'
            label='Phone number' 
            type='tel'
            span={6}
            values={values}
            onChange={e => setValues(prevState => ({ ...prevState, phone: e.target.value, }))}
          />
        </>
      )}
      actions={(
        <>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            onClick={() => setActiveStep(prevState => prevState - 1)}
            children='Back'
						disableRipple
          />
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={handleNext}
            disabled={!values?.firstName ||
                      !values?.lastName ||
                      !values?.zip ||
                      !values?.dob}
            children='Next'
						disableRipple
          />
        </>)}
    />
  )
}

export default SetupProfile