import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { listenForAuthChanges } from './redux/actions/userActions'
import LoadingScreen from './components/_common/LoadingScreen'
import PrivatePage from './components/_common/PrivatePage'
import Home from './components/home/Home'
import Landing from './components/landing/Landing'
import Locations from './components/locations/Locations'
import Media from './components/media/Media'
import Missing from './components/missing/Missing'
import Account from './components/account/Account'
import Setup from './components/setup/Setup'
import Tag from './components/tag/Tag'
import './assets/stylesheets/global.scss'

TagManager.initialize({ gtmId: 'GTM-KR29WFK', })

library.add(fas, fab)

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.rootReducer.user)
  const tags = useSelector(state => state.rootReducer.tags)
  const locations = useSelector(state => state.rootReducer.locations)
  const media = useSelector(state => state.rootReducer.media)

  useEffect(() => {
    dispatch(listenForAuthChanges())
  }, [dispatch])

  return (
    <BrowserRouter>
      {user.isLoading || tags.isLoading || locations.isLoading || media.isLoading ? <LoadingScreen /> : null}
      <Routes>
        <Route path='/' element={
          !user.data
          ? <Landing />
          : !user.data.username
            ? <Setup />
            : <PrivatePage><Home /></PrivatePage>
        } />
        <Route exact path='/locations' element={
          <PrivatePage><Locations /></PrivatePage>
        } />
        <Route exact path='/media' element={
          <PrivatePage><Media /></PrivatePage>
        } />
        <Route exact path='account' element={
          <PrivatePage><Account /></PrivatePage>
        } />
        <Route path='/tag' element={
          <PrivatePage><Tag /></PrivatePage>
        } />
        <Route path='*' element={<Missing />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App