import { combineReducers } from 'redux'
import user from './userSlice'
import tags from './tagsSlice'
import locations from './locationsSlice'
import media from './mediaSlice'
import inactiveMedia from './inactiveMediaSlice'

const rootReducer = combineReducers({
  user,
  tags,
  locations,
  media,
	inactiveMedia,
})

export default rootReducer