import { alpha, Box } from '@mui/material'
import { useSelector } from 'react-redux'

import { theme } from '../../themes/theme'
import inactiveGraphic from '../../assets/images/tag-graphic-1.svg'
import activeGraphic from '../../assets/images/tag-graphic-2.svg'

const TagGraphic = () => {
	const styles = {
		wrapper: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 2,
			height: 144,
			width: 144,
			[theme.breakpoints.down('sm')]: {
				height: 120,
				width: 120,
			},
			'& img': {
				position: 'relative',
				zIndex: 2,
				height: '100%',
			},
		},
		pulseRing: {
			position: 'absolute',
			zIndex: 1,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			background: `radial-gradient(${alpha(theme.palette.primary.main, 0)} 30%, ${alpha(theme.palette.primary.main, 0.5)})`,
			transform: 'scale(2)',
			animationName: 'pulse-ring',
			animationDuration: '1.6s',
			animationTimingFunction: 'ease-out',
			animationIterationCount: 'infinite',
		},
	}
  const user = useSelector(state => state.rootReducer.user.data)
	const tag = useSelector(state => state.rootReducer.tags.data?.[0])
  const accountActive = user?.activation_status === 'active' || user?.activation_status === 'trialing'

  return (
    <Box sx={styles.wrapper}>
      {accountActive && tag && <Box sx={styles.pulseRing} />}
      {accountActive && tag
				? <img src={activeGraphic} alt='Tag indicator graphic' />
				: <img src={inactiveGraphic} alt='Tag indicator graphic' />}
    </Box>
  )
}
 
export default TagGraphic