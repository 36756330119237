import { createTheme, keyframes } from '@mui/material'
import { breakpoints } from './breakpoints'
import { palette } from './palette'
import { typography } from './typography'
import { Button } from './components/Button'
import { Card } from './components/Card'
import { Chip } from './components/Chip'
import { IconButton } from './components/IconButton'
import { Link } from './components/Link'
import { Accordion, AccordionSummary } from './components/Accordion'
import { Dialog } from './components/Dialog'

export const theme = createTheme({
  ...breakpoints,
  ...palette,
  ...typography,
  components: {
    ...Button,
    ...Card,
    ...Chip,
    ...IconButton,
    ...Link,
    ...Accordion,
    ...AccordionSummary,
    ...Dialog,
  },
})

export const global = {
  styles: {
    favoritor: {
      position: 'absolute',
      top: 8,
      right: 8,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      opacity: 0,
      transition: 'opacity 0.1s ease-out',
      // '& > *': { borderRadius: '50%',},
      '&.solid':  {
        opacity: 1,
        '& > *': { opacity: 1, },
      },
    },

    // Forms
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      margin: '0px 0px 8px',
    },
    formActions: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
      padding: '8px 0 0',
    },
  },
}

export const loader = keyframes`
  0%   { opacity: 1.0; }
  50%  { opacity: 0.5; }
  100% { opacity: 1.0; }
`