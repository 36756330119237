import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LightboxActionFavorite from './LightboxActionFavorite'
import LightboxActionCoverset from './LightboxActionCoverset'
import LightboxActionDownload from './LightboxActionDownload'
import LightboxActionMore from './LightboxActionMore'

const LightboxAsideActions = ({ media, index, handleCloseModal, setUpdateFavorite }) => {
  const { width } = useWindowDimensions()
	const fileType = media?.fileType
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			height: 70,
			[theme.breakpoints.down('md')]: {
				gap: 0.75,
				height: 88,
			},
		},
	}

  return (
    <Box sx={styles.wrapper}>
			<LightboxActionFavorite media={media} setUpdateFavorite={setUpdateFavorite} />
			{width < 992 && fileType === 'image' && <LightboxActionCoverset media={media} index={index} />}
      <LightboxActionDownload media={media} index={index} />
      <LightboxActionMore media={media} handleCloseModal={handleCloseModal} />
    </Box>
  )
}
 
export default LightboxAsideActions