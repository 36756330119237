import { Link } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'

import { theme } from '../../../themes/theme'
import { useWindowDimensions } from '../../../utilities/getWindowDimensions'

const NavMajor = ({ to, icon, label }) => {
	const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: 16,
			height: 32,
			paddingLeft: 20,
			paddingRight: 20,
			textDecoration: 'none',
			color: theme.palette.text.secondary,
		},
		icon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: 16,
		},
		button: {
			justifyContent: 'flex-start',
			gap: 1.25,
			width: 180,
			height: 36,
			'& svg': {
				width: 16,
				height: 16,
			},
		},
		label: {
			color: theme.palette.text.secondary,
			lineHeight: 1,
		},
	}

	const desktopMarkup = (
		<Link style={styles.wrapper} to={to}>
			<Box sx={styles.icon}>{icon}</Box>
			<Typography variant='body4' sx={styles.label}>{label}</Typography>
		</Link>
	)

	const mobileMarkup = (
		<Button variant='text' color='secondary' component={Link} to={to} sx={styles.button} disableRipple>
			<Box sx={styles.icon}>{icon}</Box>
			<Typography variant='button1' sx={styles.label}>{label}</Typography>
		</Button>
	)

	return width > 992 ? desktopMarkup : mobileMarkup
}
 
export default NavMajor