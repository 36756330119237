import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const TagRetroactiveDrawer = () => {
	const inactiveMedia = useSelector(state => state.rootReducer.inactiveMedia.data)
	const [drawerOpen, setDrawerOpen] = useState(false)
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			textAlign: 'center',
		},
		puller: {
			position: 'absolute',
			top: 12,
			left: 'calc(50% - 18px)',
			width: 36,
			height: 6,
			borderRadius: 3,
			backgroundColor: theme.palette.neutrals[500],
			[theme.breakpoints.up('md')]: { display: 'none', },
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 3.5,
			paddingTop: 7,
			paddingLeft: 3,
			paddingRight: 3,
			paddingBottom: 7,
			width: '100%',
			maxWidth: 420,
			[theme.breakpoints.up('md')]: { paddingBottom: 8, },
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 2,
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 3,
				paddingRight: 3,
			},
		},
		actions: {
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
			width: '100%',
			maxWidth: 260,
			[theme.breakpoints.down('sm')]: { maxWidth: 'none', },
		},
	}

	useEffect(() => {
		const drawerDismissed = sessionStorage.getItem('retroTagDrawerDismissed')
		if (!!inactiveMedia?.length && drawerDismissed !== 'true') setDrawerOpen(true)
	}, [inactiveMedia])

	const handleDrawerClose = () => {
		setDrawerOpen(false)
		sessionStorage.setItem('retroTagDrawerDismissed', 'true')
	}

	return (
		<SwipeableDrawer
			anchor='bottom'
			open={drawerOpen}
			onClose={handleDrawerClose}
			onOpen={() => setDrawerOpen(true)}
			PaperProps={{ sx: { borderRadius: '20px 20px 0 0', } }}
		>
			<Box sx={styles.wrapper}>
				<Box sx={styles.puller} />
				<Box sx={styles.container}>
					<Box sx={styles.content}>
						<Typography variant='h1' children="Your media is waiting" />
						<Typography variant='body1' children="Looks like you’ve already got some Hightag shots! Choose any activation plan to access them." />
					</Box>
					<Box sx={styles.actions}>
						<Button
							sx={{ width: '100%', }}
							variant='contained'
							color='secondary'
							size='large'
							children="Got it&nbsp;&nbsp;👍"
							onClick={handleDrawerClose}
							disableRipple
						/>
					</Box>
				</Box>
			</Box>
		</SwipeableDrawer>
	)
}
 
export default TagRetroactiveDrawer