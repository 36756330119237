import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Stack } from '@mui/material'
import { getDocs, collection } from 'firebase/firestore'

import { db } from '../../firebase/initialize'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LocationsList from './LocationsList'
import LocationsMap from './LocationsMap'
import { fetchLocations } from '../../redux/actions/locationsActions'

const Locations = () => {
  const dispatch = useDispatch()
  const locations = useSelector(state => state.rootReducer.locations.data)
  const [devices, setDevices] = useState([])
  const [focus, setFocus] = useState(null)
  const { width } = useWindowDimensions()

  useEffect(() => {
    dispatch(fetchLocations())

    const fetchDevices = async () => {
      try {
        const devicesDocs = await getDocs(collection(db, 'devices'))
        const devicesData = devicesDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        setDevices(devicesData)
      } catch (error) {
        console.error('Error fetching devices:', error)
      }
    }

    fetchDevices()
  }, [dispatch])

  // Enrich locations data with devices data
  const enrichLocationsWithDevices = locations
    ? locations.map(location => {
        const enrichedTrails = location.trails?.map(trail => {
          const trailDevices = devices.filter(device => device.trail_id === trail.id)
          return { ...trail, devices: trailDevices }
        }) || []

        return { ...location, trails: enrichedTrails }
      })
    : []

  return (
    <Stack direction={width < 992 ? 'column-reverse' : 'row'}>
      <LocationsList locations={enrichLocationsWithDevices} devicesList={devices} focus={focus} setFocus={setFocus} />
      <LocationsMap locations={enrichLocationsWithDevices} devicesList={devices} focus={focus} />
    </Stack>
  )
}

export default Locations