import { useState } from 'react'
import { useSelector } from 'react-redux'
import { doc } from 'firebase/firestore'
import { Button, FormControl, FormGroup, FormControlLabel, Checkbox, CircularProgress, alpha } from '@mui/material'
import { useSnackbar } from 'notistack'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import { mixpanel } from '../../mixpanel/initialize'
import { sportsList } from '../../utilities/sportsList'
import SetupForm from './SetupForm'

const SetupSports = ({ values, setValues, setActiveStep, batch }) => {
  const user = useSelector(state => state.rootReducer.user.data)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
	const styles = {
		checkboxGroup: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
			gap: 2,
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
				gap: 1,
			},
		},
		checkboxLabel: {
			justifyContent: 'flex-start',
			alignItems: 'flex-end',
			aspectRatio: '3 / 2',
			margin: 0,
			padding: 1,
			borderRadius: 3,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			'&:hover': { opacity: 0.9, },
			[theme.breakpoints.down('sm')]: {
				padding: 0,
				borderRadius: 2,
			},
			'& .MuiTypography-root': {
				marginBottom: 1,
				fontSize: 20,
				color: theme.palette.common.white,
				[theme.breakpoints.down('sm')]: { fontSize: 17, lineHeight: 1.5, },
			},
		},
		checkbox: {
			color: theme.palette.common.white,
			'& svg': { color: theme.palette.common.white, },
		},
	}

  const handleCheckbox = (e) => {
    let sports = values?.sports || []

    sports?.includes(e.target.name) ?
      sports = sports.filter(x => x !== e.target.name) :
      sports.push(e.target.name)

    return setValues((prevState) => ({ ...prevState, sports }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    let errors = []

    if (values?.sports?.length < 1) errors.push({ message: `Must select at least 1 sport.` })
    for (var i = 0; i < values?.sports; i++) {
      if (!sportsList.includes(values?.sports[i])) errors.push({ message: `Invalid sport selection.` })
    }

    if (errors?.length > 0) {
      errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      
      setLoading(false)
      return errors = []
    }

    try {
      mixpanel.people.set({
        $first_name: values?.firstName,
        $last_name: values?.lastName,
        $phone: values?.phone,
        $dob: values?.dob,
      })
      batch.set(doc(db, 'usernames', values?.username), { uid: user?.id })
      batch.update(doc(db, 'users', user?.id), values)
      await batch.commit()
      enqueueSnackbar(`Welcome to Hightag, ${values?.firstName}!`, { variant: 'success' })
    } catch (error) {
      console.error(error)
      setLoading(false)
      enqueueSnackbar('Something went wrong.', { variant: 'error' })
    }
  }

  return (
    <SetupForm
      title='My sports'
      message='Select at least 1 sport you participate in.'
      children={(
        <FormControl sx={{ gridColumn: 'span 6', }}>
          <FormGroup sx={styles.checkboxGroup}>
            {sportsList.map((sport, index) => (
              <FormControlLabel
                key={index}
                label={sport.name}
                sx={{
                  ...styles.checkboxLabel,
                  backgroundImage:
                    `linear-gradient(
                      ${alpha(theme.palette.common.black, 0.0)} 50%,
                      ${alpha(theme.palette.common.black, 0.6)}
                    ), url(${sport.img})`,
                }}
                control={
                  <Checkbox
                    name={sport.name}
                    sx={styles.checkbox}
                    onChange={handleCheckbox}
                    checked={!!values?.sports?.includes(sport.name)}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
      actions={(
        <>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
            children='Back'
						disableRipple
          />
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={handleSubmit}
            children={loading ? <CircularProgress /> : 'Finish'}
            disabled={!values?.sports?.length}
						disableRipple
          />
        </>
      )}
    />
  )
}

export default SetupSports