import HomeSessions from './HomeSessions'
import HomeFavorites from './HomeFavorites'

const HomeMedia = () => {
	return (
		<>
			<HomeSessions />
			<HomeFavorites />
		</>
	)
}
 
export default HomeMedia