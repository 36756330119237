import { Popover, Box, Button, Typography } from '@mui/material'

import { useWindowDimensions } from '../../utilities/getWindowDimensions'

const LightboxActionsDropdown = ({ dropdownId, dropdownOpen, dropdownAnchor, setDropdownAnchor, setDeleteModalOpen }) => {
	const styles = {
		popover: { '& .MuiPaper-root': { borderRadius: 2, }, },
		wrapper: {
			padding: 1,
			minWidth: 200,
		},
		button: { width: '100%', },
	}
  const { width } = useWindowDimensions()
  const handleDropdownClose = () => { setDropdownAnchor(null) }

  return (
    <Popover
        id={dropdownId}
        open={dropdownOpen}
        anchorEl={dropdownAnchor}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: width > 992 ? 'bottom' : 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: width > 992 ? 'top' : 'bottom',
          horizontal: 'right',
        }}
        sx={styles.popover}
    >
      <Box sx={styles.wrapper}>
        <Button variant='text' color='secondary' size='small' sx={styles.button} onClick={() => setDeleteModalOpen(true)} disableRipple>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M147.2 0H160 288h12.8L308 10.7 354.2 80h13.7H416h32v48H412.4L384 512H64L35.6 128H0V80H32 80.1 93.8L140 10.7 147.2 0zm4.4 80h145L275.2 48H172.8L151.5 80zM83.7 128l24.9 336H339.4l24.9-336H83.7z"/>
          </svg>
          <Typography variant='button2'>Delete</Typography>
        </Button>
      </Box>
    </Popover>
  )
}
 
export default LightboxActionsDropdown