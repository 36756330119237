import { theme } from '../../themes/theme'

export const styles = {
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 2,
    paddingBottom: 2,
  },
  switchLink: {
    marginTop: 2,
    color: theme.palette.text.secondary,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: 2,
    },
  },
  message: {
    marginBottom: 2,
    color: theme.palette.text.secondary,
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: { width: '100%', marginTop: 1, },
  },
}