import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Tooltip } from '@mui/material'
import { useDebounce } from 'use-debounce'

import { theme } from '../../themes/theme'
import { checkUsernameAvailability } from '../../utilities/checkUsernameAvailability'
import TextInput from '../_common/TextInput'
import AccountProfileSports from './AccountProfileSports'
import AccountProfileSubmit from './AccountProfileSubmit'

const AccountProfileForm = () => {
	const styles = {
		formGrid: {
			display: 'grid',
			gridTemplateColumns: 'repeat(6, 1fr)',
			gap: '20px 12px',
			paddingTop: 4,
			paddingLeft: 4,
			paddingRight: 4,
			[theme.breakpoints.down('md')]: {
				gap: 2,
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
	}
  const user = useSelector(state => state.rootReducer.user.data)
  const initialValues = {
    username: user?.username,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    city: user?.city,
    state: user?.state,
    zip: user?.zip,
    dob: user?.dob,
    sports: user?.sports,
  }
  const [values, setValues] = useState(initialValues)
  const [usernameAllowed, setUsernameAllowed] = useState(true)
  const [usernameInput] = useDebounce(values?.username, 500)

  useEffect(() => {
    if (usernameInput) {
      checkUsernameAvailability(usernameInput)
        .then((isAvailable) => {
          const isCurrentUsername = usernameInput === user.username
          setUsernameAllowed(isAvailable || isCurrentUsername)
        })
    }
  }, [usernameInput, user.username])

  return (
    <>
      <Box sx={styles.formGrid}>
        <Tooltip title="Sorry, email can't be changed at this time." arrow>
					<> {/* empty fragment to avoid error */}
						<TextInput
							name='email'
							label='Email'
							span={3}
							defaultValue={values?.email}
							values={values}
							onChange={e => setValues(prevState => ({ ...prevState, email: e.target.value, }))}
							required
							disabled
						/>
					</>
        </Tooltip>
        <TextInput
          name='username'
          label='Username'
          span={3}
          defaultValue={values?.username}
          values={values}
          onChange={e => setValues(prevState => ({ ...prevState, username: e.target.value, }))}
          required
        />
        <TextInput
          name='firstName'
          label='First name' 
          span={3}
          defaultValue={values?.firstName}
          values={values}
          onChange={e => setValues(prevState => ({ ...prevState, firstName: e.target.value, }))}
          required
        />
        <TextInput
          name='lastName'
          label='Last name' 
          span={3}
          defaultValue={values?.lastName}
          values={values}
          onChange={e => setValues(prevState => ({ ...prevState, lastName: e.target.value, }))}
          required
        />
        <TextInput
          name='dob'
          label='Birthdate'
          type='date'
          span={2}
          defaultValue={values?.dob}
          values={values}
          onChange={e => setValues(prevState => ({ ...prevState, dob: e.target.value, }))}
          required
        />
        <TextInput
          name='phone'
          label='Phone number' 
          type='tel'
          span={2}
          defaultValue={values?.phone}
          values={values}
          onChange={e => setValues(prevState => ({ ...prevState, phone: e.target.value, }))}
        />
        <TextInput
          name='zip'
          label='ZIP code'
          span={2}
          defaultValue={values?.zip}
          values={values}
          onChange={e => setValues(prevState => ({ ...prevState, zip: e.target.value, }))}
          required
        />
        <AccountProfileSports values={values} setValues={setValues} />
      </Box>
      <AccountProfileSubmit values={values} setValues={setValues} usernameAllowed={usernameAllowed} />
    </>
  )
}

export default AccountProfileForm