export const colors = {
  black: '#000000',
  space: '#292C2E',
  spaceCadet: '#323638',
  cadet: '#4E5357',
  slate: '#878D91',
  gray: '#CCCFD0',
  steel: '#E6E7E8',
  snow: '#F0F1F2',
  snowWhite: '#FAFAFA',
  platinum: '#FAFAFA',
  white: '#FFFFFF',

  orange: {
    base: '#FF5533',
    light: '#FFF5F2',
    dark: '#E74322',
  },

  blue: {
    base: '#3A86FF',
    light: '#F2F9FF',
    dark: '#1B60CF',
  },

  green: {
    base: '#2AC182',
    light: '#ECF6F2',
    dark: '#16734C',
  },

  yellow: {
    base: '#FFCC44',
    light: '#FFF8E7',
    dark: '#E7B225',
  },
  red: '#FF4838',
}