import { Box } from '@mui/material'

export default function Container({ children }) {
	const styles = {
		wrapper: {
			flex: 1,
			position: 'relative',
			zIndex: 1,
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	}
	
  return (
    <Box sx={styles.wrapper} id='container'>
      {children}
    </Box>
  )
}