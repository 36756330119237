import { useState } from 'react'
import { alpha, Box, CircularProgress } from '@mui/material'

import { theme } from '../../themes/theme'
import LightboxImageDisplay from './LightboxImageDisplay'
import LightboxVideoDisplay from './LightboxVideoDisplay'

const LightboxMainBody = ({ media, index, videoRef, isPlaying, handleTogglePlaying }) => {
  // eslint-disable-next-line
  const [imageLoaded, setImageLoaded] = useState(false)
  const path = media?.path[index]
	const fileType = media?.fileType
	const styles = {
		wrapper: {
			position: 'relative',
			height: 'calc(100% - 70px - 64px)', // Subtract space for head and foot
			backgroundColor: theme.palette.background.darkest,
			[theme.breakpoints.down('md')]: {
				marginTop: 8.75,
				height: 'auto',
				aspectRatio: '3 / 2',
			},
		},
		loader: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%',
			backgroundColor: alpha(theme.palette.background.darker, 0.5),
		},
	}

	let display = null
	if (fileType === 'image') display = <LightboxImageDisplay path={path} setImageLoaded={setImageLoaded} />
	// else if (fileType === 'video') display = <LightboxVideoDisplay path={path} videoRef={videoRef} isPlaying={isPlaying} handleTogglePlaying={handleTogglePlaying} />
	else if (fileType === 'video') display = <LightboxVideoDisplay path={path} videoRef={videoRef} />

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.loader}>
        <CircularProgress color='secondary' />
      </Box>
			{display}
    </Box>
	)
}

export default LightboxMainBody