import { useState } from 'react'
import { Box, Button, SwipeableDrawer, Tab, Typography, alpha } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { theme } from '../../themes/theme'
import logo from '../../assets/images/logo.svg'
import topo from '../../assets/images/topo-space.svg'
import LandingOldUser from './LandingOldUser'
import LandingNewUser from './LandingNewUser'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    width: '100%',
    height: '100%',
    paddingTop: 0,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 0,
    textAlign: 'center',
    background: 
      `linear-gradient(${alpha(theme.palette.background.paper, 0.96)}, ${alpha(theme.palette.background.paper, 0.96)}),
      url(${topo})`,
    backgroundSize: 'auto 130%',
    backgroundPosition: '105% 20%',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: { backgroundPosition: '180% 20%', },
    [theme.breakpoints.down('sm')]: { backgroundPosition: '-50% 20%', },
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 0,
  },
  foot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    paddingBottom: 5,
  },
  footLink: {
    fontSize: 15,
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
  logo: {
    width: '45vw',
    maxWidth: 200,
    marginTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
  },
  tagline: {
    position: 'relative',
    zIndex: 1,
    marginTop: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 3,
    lineHeight: 0.9,
    fontSize: 104,
    [theme.breakpoints.down('sm')]: { fontSize: 64, },
  },
  action: {
    position: 'relative',
    zIndex: 1,
    marginTop: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 3,
    lineHeight: 0.9,
    fontSize: 104,
    [theme.breakpoints.down('sm')]: { fontSize: 64, },
    color: theme.palette.primary.main
  },
  message: {
    fontSize: 22,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      fontSize: 20,
    },
  },
  start: {[theme.breakpoints.down('sm')]: { width: '100%', }},
  drawer: { '& .MuiDrawer-paper': { [theme.breakpoints.down('md')]: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  }, }, },
  puller: {
    width: 40,
    height: 6,
    marginTop: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 2,
    borderRadius: 3,
    backgroundColor: theme.palette.neutrals[500],
    [theme.breakpoints.up('md')]: { display: 'none', },
  },
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabs: {
    width: '100%',
    maxWidth: 480,
  },
  tabPanel: {
    height: 200,
    [theme.breakpoints.down('sm')]: {
      height: 360,
    },
  },
}

const Landing = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [value, setValue] = useState('login')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.body}>
        <img style={styles.logo} src={logo} alt='Hightag' />
        <Typography variant='display1' sx={styles.tagline}>
          Capture the <Typography variant='display1' sx={styles.action}>action</Typography>
        </Typography>
        <Typography variant='subtitle1' sx={styles.message}>
					Capture footage of yourself automatically while you ride.
        </Typography>
        <Button sx={styles.start} variant='contained' color='primary' size='large' onClick={() => setDrawerOpen(true)} disableRipple>
          <Typography variant='button1'>Get started</Typography>
        </Button>
      </Box>
      <Box sx={styles.foot}>
        <Typography sx={styles.footLink} variant='body4' color='textSecondary'>© 2023 Hightag Inc.</Typography>
        <a href='https://www.hightag.com/terms/website' style={styles.footLink}>Terms</a>
        <a href='https://www.hightag.com/terms/privacy' style={styles.footLink}>Privacy</a>
        {/* <a href='https://hightag.com' style={styles.footLink} className='md-down-hide'>
          <FontAwesomeIcon size='lg' icon={icon({ name: 'apple', family: 'classic', style: 'brands', })} />
        </a>
        <a href='https://hightag.com' style={styles.footLink} className='md-down-hide'>
          <FontAwesomeIcon icon={icon({ name: 'google-play', family: 'classic', style: 'brands', })} />
        </a> */}
        <a href='https://www.instagram.com/hightag.app/' style={styles.footLink} className='md-down-hide'>
          <FontAwesomeIcon icon={icon({ name: 'instagram', family: 'classic', style: 'brands', })} />
        </a>
      </Box>
      <SwipeableDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        anchor='bottom'
        sx={styles.drawer}
      >
        <Box sx={styles.puller}></Box>
        <TabContext value={value}>
          <Box sx={styles.tabsWrapper}>
            <TabList variant='fullWidth' onChange={handleChange} sx={styles.tabs} centered>
              <Tab label='Login' value='login' />
              <Tab label='Sign up' value='register' />
            </TabList>
          </Box>
          <TabPanel sx={styles.tabPanel} value='login'><LandingOldUser /></TabPanel>
          <TabPanel sx={styles.tabPanel} value='register'><LandingNewUser /></TabPanel>
        </TabContext>
      </SwipeableDrawer>
    </Box>
  )
}

export default Landing