import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { Button, Typography } from '@mui/material'

import { auth } from '../../firebase/initialize'
import { theme } from '../../themes/theme'

const AccountSignOut = () => {
  const navigateTo = useNavigate()

  const handleSignOut = async () => {
    await signOut(auth)
    navigateTo('/')
  }

  return (
    <Button variant='outlined' color='inherit' size='small' onClick={() => handleSignOut()} disableRipple>
      <Typography variant='button2'>Log out</Typography>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 512 512"><path fill={theme.palette.text.primary} d="M502.6 278.6L525.3 256l-22.6-22.6-128-128L352 82.7 306.7 128l22.6 22.6L402.7 224 192 224l-32 0 0 64 32 0 210.7 0-73.4 73.4L306.7 384 352 429.3l22.6-22.6 128-128zM160 96l32 0 0-64-32 0L32 32 0 32 0 64 0 448l0 32 32 0 128 0 32 0 0-64-32 0-96 0L64 96l96 0z"/></svg>
    </Button>
  )
}

export default AccountSignOut