import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { theme } from '../../themes/theme'
import MediaSessions from'./MediaSessions'
import MediaAll from './MediaAll'
import MediaFavorites from './MediaFavorites'

const MediaMain = ({ media, scrollTo, onSessionClick }) => {
	const [searchParams] = useSearchParams()
	const activeTab = searchParams.get('tab')
	const styles = {
		main: {
			flexGrow: 1,
			[theme.breakpoints.down('md')]: {
				marginLeft: -2.5,
				marginRight: -2.5,
				paddingLeft: 2.5,
				paddingRight: 2.5,
				paddingBottom: '104px',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: -2,
				marginRight: -2,
				paddingLeft: 2,
				paddingRight: 2,
			},
		},
		slide: {
			width: '100%',
			height: '100%',
		},
		hiddenSlide: { display: 'none', },
	}
	
	return (
		<Box sx={styles.main} id="Main">
			<Box sx={activeTab === 'sessions' ? styles.slide : {...styles.slide, ...styles.hiddenSlide}}>
				<MediaSessions media={media} onSessionClick={onSessionClick} />
			</Box>
			<Box sx={activeTab === 'all' ? styles.slide : {...styles.slide, ...styles.hiddenSlide}}>
				<MediaAll media={media} scrollTo={scrollTo} />
			</Box>
			<Box sx={activeTab === 'favorites' ? styles.slide : {...styles.slide, ...styles.hiddenSlide}}>
				<MediaFavorites media={media} />
			</Box>
		</Box>
	)
}
 
export default MediaMain