import { useRef, useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { auth, db } from '../../firebase/initialize'
import { mixpanel } from '../../mixpanel/initialize'
import { decode } from '../../utilities/errorDecoding'
import Snackbar from '../_common/Snackbar'
import { styles } from './formStyles'

const LandingNewUser = () => {
  const timestamp = serverTimestamp()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setLoading(false)
      console.error('Passwords do not match.')
      return setSnackbar({ open: true, type: 'error', message: 'Passwords do not match.' })
    }

    try {
      const credentials = await createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)

      await setDoc(doc(db, 'users', credentials.user.uid), {
        email: emailRef.current.value,
        createdAt: timestamp,
      })

      await credentials.user.sendEmailVerification()
      mixpanel.track('Sign Up')
    } catch(error) {
      console.error(error)
      setSnackbar({ open: true, type: 'error', message: decode(error.code) })
      setLoading(false)
    }
  }

  return (
    <Box sx={styles.formWrapper}>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
      <Typography variant='body3' sx={styles.message}>Enter your email and a password to create an account.</Typography>
      <Box sx={{ ...styles.form, maxWidth: 880, }}>
        <input className='form__textfield' type='email' ref={emailRef} placeholder='Email address' required />
        <input className='form__textfield' type='password' ref={passwordRef} placeholder='Password' required />
        <input className='form__textfield' type='password' ref={passwordConfirmRef} placeholder='Confirm password' required />
        <Box sx={styles.submit}>
          <Button variant='contained' color='primary' size='large' onClick={handleSubmit} disabled={loading} disableRipple>
            {loading ?
              <CircularProgress /> :
              <Typography variant='button1'>Sign up</Typography>}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default LandingNewUser