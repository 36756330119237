import { query, collection, where, onSnapshot } from 'firebase/firestore'

import { db } from '../../firebase/initialize'
import { setTagsRequested, setTagsSucceeded, setTagsFailed } from '../reducers/tagsSlice'

const convertTimestamp = (timestamp) => {
  return timestamp ? new Date(timestamp * 1000).getTime() : null
}

export const fetchTags = (user) => async (dispatch) => {
  dispatch(setTagsRequested())

  if (!user?.id) {
    dispatch(setTagsSucceeded(null))
    return
  }
  
  try {
    const tagsQryRef = query(collection(db, 'tags'), where('user_id', '==', user.id))

    onSnapshot(
      tagsQryRef,
      (snapshot) => {
        try {
          let tagsArr = []

          snapshot.forEach((doc) => {
            const id = doc.id
            const data = doc.data()
            const activationExpiration = convertTimestamp(data?.activation_expiration?.seconds)
            const tagObj = {
              ...data,
              id,
              activation_expiration: activationExpiration,
            }

            tagsArr.push(tagObj)
          })

          dispatch(setTagsSucceeded(tagsArr))
        } catch (error) {
          console.error('Snapshot processing error:', error)
          dispatch(setTagsFailed(error.message))
        }
      },
      (error) => {
        console.error('Snapshot listener error:', error)
        dispatch(setTagsFailed(error.message))
      }
    )
  } catch (error) {
    console.error('Firestore error:', error)
    dispatch(setTagsFailed(error.message))
  }
}