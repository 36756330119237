import { useEffect, useRef } from 'react'
import { Box } from '@mui/material'

import { groupMedia } from '../../utilities/groupMedia'
import MediaAllGroup from './MediaAllGroup'

const MediaAll = ({ media, scrollTo }) => {
  const contentRef = useRef(null)
	const groups = groupMedia(media)
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: 1.5,
		},
	}

  useEffect(() => {
    if (contentRef.current && scrollTo) {
      const targetElement = contentRef.current.querySelector(`#${scrollTo}`)
      if (targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [scrollTo])

	return (
    <Box sx={styles.wrapper} ref={contentRef}>
      {groups?.map((group, index) => (
        <MediaAllGroup group={group} key={index} index={index} />
      ))}
    </Box>
  )
}

export default MediaAll