import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import LightboxInfo from './LightboxInfo'

const LightboxInfoLocation = ({ media }) => {
	const styles = {
		wrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		button: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 1,
			paddingRight: 1.5,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 40,
		},
	}
  const { width } = useWindowDimensions()
  const location = media?.locationName
  const trail = media?.trail_name

  let markup = (
    <LightboxInfo
			icon={<svg xmlns="http://www.w3.org/2000/svg" width="0.9em" viewBox="0 0 16 20" fill="none"><path fill={theme.palette.text.primary} fillRule="evenodd" clipRule="evenodd" d="M13.7893 13.5212C15.0036 12 16 10.1409 16 8C16 3.5817 12.4183 0 8 0C3.5817 0 0 3.5817 0 8C0 10.1409 0.9979 12 2.2106 13.5212L8 20L13.7893 13.5212ZM8 10.5C9.3807 10.5 10.5 9.3807 10.5 8C10.5 6.6193 9.3807 5.5 8 5.5C6.6193 5.5 5.5 6.6193 5.5 8C5.5 9.3807 6.6193 10.5 8 10.5Z"/></svg>}
			major={location}
			minor={trail}
    />
  )

  if (width < 992) markup = (
    <Box sx={styles.wrapper}>
      <Box sx={styles.button}>
				<svg xmlns="http://www.w3.org/2000/svg" style={{ height: 13, }} viewBox="0 0 17 20" fill="none" className='nav-icon'><path fill={theme.palette.text.primary} fillRule="evenodd" clipRule="evenodd" d="M14.4893 13.5212C15.7036 12 16.7 10.1409 16.7 8C16.7 3.5817 13.1183 0 8.69998 0C4.28168 0 0.699982 3.5817 0.699982 8C0.699982 10.1409 1.69788 12 2.91058 13.5212L8.69998 20L14.4893 13.5212ZM8.69998 10.5C10.0807 10.5 11.2 9.3807 11.2 8C11.2 6.6193 10.0807 5.5 8.69998 5.5C7.31928 5.5 6.19998 6.6193 6.19998 8C6.19998 9.3807 7.31928 10.5 8.69998 10.5Z"/></svg>
        <Typography variant='body3'>{location}</Typography>
      </Box>
    </Box>
  )

  return markup
}
 
export default LightboxInfoLocation