import { Box } from '@mui/material'

import AccountProfile from './AccountProfile'
import AccountMembership from './AccountMembership'

const AccountDesktop = () => {
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'flex-start',
			gap: 20,
			width: '100%',
		},
	}

  return(
    <Box style={styles.wrapper}>
			<AccountProfile />
			<AccountMembership />
    </Box>
  )
}

export default AccountDesktop