import { Box } from '@mui/material'

import AppBar from '../_common/AppBar'
import AccountIdCard from './AccountIdCard'
import AccountMembership from './AccountMembership'
import AccountProfileForm from './AccountProfileForm'
import AccountDelete from './AccountDelete'
import AccountSignOut from './AccountSignOut'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
		paddingTop: 8.75,
  },
}

const AccountMobile = () => {
  return (
    <Box sx={styles.wrapper}>
			<AppBar right={<AccountSignOut />} />
      <AccountIdCard />
      <AccountMembership />
      <AccountProfileForm />
      <AccountDelete />
    </Box>
  )
}

export default AccountMobile