import { theme } from '../theme'

export const Chip = {
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        '& span': {
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          fontSize: 11,
          fontWeight: 500,
					letterSpacing: '0.075em',
					textTransform: 'uppercase',
        },

				// Colors

        ...(ownerState.color === 'secondary' && {
          color: theme.palette.text.inverse,
					backgroundColor: theme.palette.grey[300],
        }),

				// Sizes

        ...(ownerState.size === 'small' && {
					paddingTop: 0.5,
					paddingLeft: 3,
					paddingRight: 2,
					height: 26,
        }),

      }),
    },
  },
}