import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

const TagMessage = () => {
  const tags = useSelector(state => state.rootReducer.tags.data)
  const user = useSelector(state => state.rootReducer.user.data)
	const activeStatuses = ['active', 'trialing']
  let message

  switch (true) {
    case tags?.length && !activeStatuses.includes(user?.activation_status):
      message = "Select a plan to get started"
      break
    case !tags?.length && activeStatuses.includes(user?.activation_status):
      message = "Your account is active but you don't have a tag linked"
      break
    case tags?.length && activeStatuses.includes(user?.activation_status):
      message = "Your tag is active, go ride!"
      break
    default:
      message = "Enter your tag's 8-digit code below"
  }

  return <Typography variant='body2' sx={{ textAlign: 'center' }}>{message}</Typography>
}
 
export default TagMessage