import { useState } from 'react'

import { theme } from '../../themes/theme'
import LightboxAction from './LightboxAction'
import LightboxActionDropdown from './LightboxActionDropdown'
import LightboxActionDelete from './LightboxActionDelete'

const LightboxActionsMore = ({ media, handleCloseModal }) => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const dropdownOpen = Boolean(dropdownAnchor)
  const dropdownId = dropdownOpen ? 'more-dropdown' : undefined

	const handleOpenDropdown = (e) => setDropdownAnchor(e.currentTarget)

	return (
		<>
			<LightboxAction
        aria-describedby={dropdownId}
        action={handleOpenDropdown}
        icon={(<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill={theme.palette.text.primary} d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/></svg>)}
        label='More'
				color='secondary'
				largeOnMobile
      />
      <LightboxActionDropdown
				dropdownId={dropdownId}
				dropdownOpen={dropdownOpen}
				dropdownAnchor={dropdownAnchor}
				setDropdownAnchor={setDropdownAnchor}
				setDeleteModalOpen={setDeleteModalOpen}
      />
      <LightboxActionDelete
				deleteModalOpen={deleteModalOpen}
				setDeleteModalOpen={setDeleteModalOpen}
				setDropdownAnchor={setDropdownAnchor}
				media={media}
				handleCloseModal={handleCloseModal}
      />
		</>
	)
}
 
export default LightboxActionsMore