import { NavLink } from 'react-router-dom'
import { MenuItem, Box, Typography } from '@mui/material'

import { theme } from '../../../themes/theme'

const NavMajor = ({ to, icon, label }) => {
	const styles = {
		wrapper: { textDecoration: 'none', },
		item: {
			display: 'flex',
			alignItems: 'center',
			gap: 2.25,
			height: 48,
			minHeight: 48,
			paddingTop: 0,
			paddingLeft: 2.5,
			paddingRight: 2.5,
			paddingBottom: 0,
			borderRadius: 2,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				justifyContent: 'center',
				gap: 1,
				height: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 1,
				borderRadius: 0,
			},
			'& p': {
				color: theme.palette.text.secondary,
				[theme.breakpoints.down('md')]: { fontSize: 12, },
			},
			'&:hover p': { [theme.breakpoints.up('md')]: { color: theme.palette.text.primary, }, },
		},
		activeItem: {
			backgroundColor: theme.palette.background.shaded,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				justifyContent: 'center',
				gap: 1,
				height: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 1,
				borderRadius: 0,
				backgroundColor: 'transparent',
			},
			'& p': {
				color: theme.palette.heading.primary,
				[theme.breakpoints.down('md')]: { fontSize: 12, },
			},
			'&:hover': {[theme.breakpoints.up('lg')]: { backgroundColor: theme.palette.background.shaded, },},
			'&:hover p': { color: theme.palette.heading.primary, },
		},
		label: { lineHeight: 1, },
	}

	return (
		<NavLink style={styles.wrapper} to={to} children={navData => (
			<MenuItem sx={navData.isActive ? { ...styles.item, ...styles.activeItem } : styles.item} disableRipple>
				<Box sx={{ '& svg': { color: navData.isActive ? theme.palette.heading.primary : theme.palette.text.secondary, }, }}>{icon}</Box>
				<Typography variant='body2' sx={styles.label}>{label}</Typography>
			</MenuItem>
		)}/>
	)
}
 
export default NavMajor