import { Box, CircularProgress, alpha } from '@mui/material'

import { theme } from '../../themes/theme'

const LoadingScreen = () => {
	const styles = {
		wrapper: {
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 9999,
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backdropFilter: 'blur(5px)',
			background: `linear-gradient(
				${alpha(theme.palette.background.default, 0.9)},
				${alpha(theme.palette.background.default, 0.9)}
			)`,
		},
	}
	
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress color='primary' />
    </Box>
  )
}

export default LoadingScreen