import { useSearchParams } from 'react-router-dom'
import { Tabs, Tab } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import AppBar from '../_common/AppBar'

const MediaTabs = ({ disabled }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { width } = useWindowDimensions()
	const styles = {
		tabs: {
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.up('md')]: {
					position: 'fixed',
					top: 0,
					right: 0,
					zIndex: 2,
					display: disabled ? 'none' : 'flex',
					paddingLeft: 2.5,
					paddingRight: 3,
					width: 'calc(100vw - 280px)',
					height: 70,
					backgroundColor: theme.palette.background.default,
			},
		},
		tab: {
			position: 'relative',
			height: 64,
		},
		indicator: {
			display: disabled ? 'none' : 'block',
			top: 4,
			zIndex: -1,
			height: 40,
			borderRadius: 80,
			backgroundColor: theme.palette.divider,
		},
	}

	return width > 992
			? (
				<Tabs
					sx={styles.tabs}
					value={searchParams.get('tab')}
					onChange={(e, value) => setSearchParams({ tab: value })}
					TabIndicatorProps={{ style: styles.indicator }}
					variant={'standard'}
					centered
				>
					<Tab label='Sessions' value='sessions' disabled={disabled} disableRipple />
					<Tab label='All Photos' value='all' disabled={disabled} disableRipple />
					<Tab label='Favorites' value='favorites' disabled={disabled} disableRipple />
				</Tabs>
			)
			: (
				<AppBar
					center={
						<Tabs
							sx={styles.tabs}
							value={searchParams.get('tab')}
							onChange={(e, value) => setSearchParams({ tab: value })}
							TabIndicatorProps={{ style: styles.indicator }}
							variant={'standard'}
							centered
						>
							<Tab label='Sessions' value='sessions' disabled={disabled} disableRipple />
							<Tab label='All Photos' value='all' disabled={disabled} disableRipple />
							<Tab label='Favorites' value='favorites' disabled={disabled} disableRipple />
						</Tabs>
					}
				/>
			)
}

export default MediaTabs