import { useState } from 'react'
import { useSelector } from 'react-redux'
import { doc, writeBatch } from 'firebase/firestore'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { enqueueSnackbar } from 'notistack'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import { sportsList } from '../../utilities/sportsList'
import { mixpanel } from '../../mixpanel/initialize'

const AccountProfileSubmit = ({ values, usernameAllowed }) => {
  const user = useSelector(state => state.rootReducer.user.data)
  const [loading, setLoading] = useState(false)
  const batch = writeBatch(db)
	const styles = {
		submit: {
			display: 'flex',
			flexDirection: 'column',
			paddingTop: 3,
			paddingLeft: 4,
			paddingRight: 4,
			paddingBottom: 4,
			[theme.breakpoints.down('md')]: {
				paddingTop: 2,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 2,
			},
		},
	}

  const handleErrors = () => {
    let errors = []

    if (!values?.email) errors.push({ message: `Email can't be empty.` })
    if (values?.email && !/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(values?.email)) errors.push({ message: `Invalid email address.` })
    if (!values?.username) errors.push({ message: `Username can't be empty.` })
    if (values?.username && values?.username.length < 4) errors.push({ message: `Username must be at least 4 characters long.` })
    if (values?.username && values?.username.length > 20) errors.push({ message: `Username can't be longer than 20 characters.` })
    if (values?.username && !usernameAllowed) errors.push({ message: `Username is already taken.` })
    if (values?.username && !/^(?![_.-])[a-zA-Z0-9_.-]*(?:[a-zA-Z0-9_]|[_])$/.test(values?.username)) errors.push({ message: `Usernames can only contain letters, numbers, "-", "_", and "."` })
    if (values?.phone && !/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(values?.phone)) errors.push({ message: `Invalid phone number.` })
    if (!values?.firstName) errors.push({ message: `First name can't be empty.` })
    if (values?.firstName && !/^(?!.*([a-zA-Z- '])\1\1)[a-zA-Z- 'àáâãäåæçèéêëìíîïðòóôõöøùúûüýþÿ]{1,256}$/i.test(values?.firstName)) errors.push({ message: `First name can't have numbers or special characters.` })
    if (!values?.lastName) errors.push({ message: `Last name can't be empty.` })
    if (values?.lastName && !/^(?!.*([a-zA-Z- '])\1\1)[a-zA-Z- 'àáâãäåæçèéêëìíîïðòóôõöøùúûüýþÿ]{1,256}$/i.test(values?.lastName)) errors.push({ message: `Last name can't have numbers or special characters.` })
    if (!values?.city) errors.push({ message: `You must enter a valid 5-digit ZIP code.` })
    if (!/^\d{5}(?:-\d{4})?$/.test(values?.zip) && values?.zip) errors.push({ message: `You must enter a valid 5-digit ZIP code.` })
    if (values?.sports?.length < 1) errors.push({ message: `Must select at least 1 sport.` })
    for (var i = 0; i < values?.sports; i++) {
      if (!sportsList.includes(values?.sports[i])) errors.push({ message: `Invalid sport selection.` })
    }

    if (errors?.length > 0) return (
      errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' })),
      setLoading(false),
      errors = []
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    handleErrors()

    const oldUsernameDocRef = doc(db, 'usernames', user.username)
    batch.delete(oldUsernameDocRef)

    const newUsernameDocRef = doc(db, 'usernames', values?.username)
    batch.set(newUsernameDocRef, { uid: user.id })

    const userDocRef = doc(db, 'users', user.id)
    values?.avatar
      ? batch.update(userDocRef, values)
      : batch.update(userDocRef, { ...values, avatar: user?.avatar || '' })

    try {
      mixpanel.people.set({
        $first_name: values?.firstName,
        $last_name: values?.lastName,
        $phone: values?.phone,
        $home_zip: values?.zip,
        $dob: values?.dob,
      })
      await batch.commit()
      enqueueSnackbar('Your account settings have been saved!', { variant: 'success' })
      setLoading(false)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Something went wrong.', { variant: 'error' })
      setLoading(false)
    }
  }

  return (
    <Box sx={styles.submit}>
      <Button variant='contained' color='secondary' size='large' onClick={handleSubmit} disabled={loading} disableRipple>
        {loading
          ? <CircularProgress color='secondary' />
          : (<>
              <FontAwesomeIcon icon={icon({name: 'floppy-disk', family: 'sharp', style: 'solid'})} />
              <Typography variant='button1'>Save changes</Typography>
            </>)}
      </Button>
    </Box>
  )
}

export default AccountProfileSubmit