import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const TagBenefitsItem = ({ text }) => {
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
		},
	}

	return (
		<Box sx={styles.wrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 512 512"><path fill={theme.palette.text.primary} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM241 337l-17 17-17-17-80-80L161 223l63 63L351 159 385 193 241 337z"/></svg>
			<Typography variant='body2'>{text}</Typography>
		</Box>
	)
}

const TagBenefits = () => {
	const benefits = [
		'1-day FREE trial for new members',
		'Use your tag at any Hightag location',
		'Access all of your Hightag photos—forever',
	]
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: 1.25,
			marginBottom: 3,
			paddingLeft: 1.5,
			paddingRight: 1.5,
			width: '100%',
		},
	}

	return (
		<Box sx={styles.wrapper}>
			{benefits.map((benefit, index) => (
				<TagBenefitsItem key={index} text={benefit} />
			))}
		</Box>
	)
}
 
export default TagBenefits