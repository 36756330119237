import LightboxAction from './LightboxAction'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'

const LightboxActionClose = ({ handleCloseModal }) => {
	const { width } = useWindowDimensions()

	return (
		<LightboxAction
			label='Close'
			action={handleCloseModal}
			icon={<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill={width > 992 ? theme.palette.text.inverse : theme.palette.text.primary} d="M52.2 50.2l-17-17L1.3 67.2l17 17L190.1 256 18.2 427.8l-17 17 33.9 33.9 17-17L224 289.9 395.8 461.8l17 17 33.9-33.9-17-17L257.9 256 429.8 84.2l17-17L412.8 33.3l-17 17L224 222.1 52.2 50.2z"/></svg>}
			color='black'
		/>
	)
}
 
export default LightboxActionClose