import { useMemo, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { theme } from '../../themes/theme'
import MediaThumbnail from '../_common/MediaThumbnail'

const MediaAllGroup = ({ group = {} }) => {
	const groupDate = group.date
  const groupRef = useRef(null)
	let groupItems = group.media
	const locationName = groupItems[0].locationName
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('md')]: {
				marginLeft: -1,
				marginRight: -1,
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: -1.75,
				marginRight: -1.75,
			},
		},
		head: {
			position: 'sticky',
			top: 69,
			zIndex: 2,
			alignSelf: 'flex-start',
			display: 'flex',
			flexDirection: 'column',
			gap: 0.5,
			width: '100% !important',
			height: 32,
			backgroundColor: theme.palette.background.default,
			[theme.breakpoints.down('md')]: {
				paddingTop: 1.5,
				paddingLeft: 1,
				paddingRight: 1,
				height: 44,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 1.75,
				paddingRight: 1.75,
			},
		},
		headContent: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		location: {
			display: 'flex',
			alignItems: 'center',
			gap: 0.25,
		},
		corners: {
			position: 'absolute',
			top: '99%',
			left: 0,
			right: 0,
			zIndex: 3,
			display: 'flex',
			justifyContent: 'space-between',
		},
		corner: {
			height: 20,
			aspectRatio: '1 / 1',
			[theme.breakpoints.down('sm')]: { height: 16, },
		},
		body: {
			flex: 1,
			position: 'relative',
			zIndex: 1,
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
			gap: 0.25,
			borderRadius: 5,
			overflow: 'hidden',
			backgroundColor: theme.palette.background.shaded,
			[theme.breakpoints.down('md')]: { gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', },
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(auto-fill, minmax(110px, 1fr))',
				borderRadius: 4,
			},
		},
	}

	const markup = useMemo(() => {
		if (!groupItems?.length) return null

    return groupItems.sort((a, b) => b.datetime - a.datetime).map((mediaItem, index) => (
			<MediaThumbnail mediaItem={mediaItem} key={index} />
		))
	}, [groupItems])

	return (
    <Box sx={styles.wrapper} className='group' ref={groupRef} id={groupDate.replace(/\s/g, '')}>
      <Box sx={styles.head} className='group__head'>
				<Box sx={styles.headContent}>
					<Typography variant='h3' sx={{ zIndex: 2 }}>{dayjs(groupDate).format('MMMM D')}</Typography>
					<Box sx={styles.location}>
						<svg xmlns="http://www.w3.org/2000/svg" style={{ height: 13, }} viewBox="0 0 17 20" fill="none" className='nav-icon'><path fill={theme.palette.text.primary} fillRule="evenodd" clipRule="evenodd" d="M14.4893 13.5212C15.7036 12 16.7 10.1409 16.7 8C16.7 3.5817 13.1183 0 8.69998 0C4.28168 0 0.699982 3.5817 0.699982 8C0.699982 10.1409 1.69788 12 2.91058 13.5212L8.69998 20L14.4893 13.5212ZM8.69998 10.5C10.0807 10.5 11.2 9.3807 11.2 8C11.2 6.6193 10.0807 5.5 8.69998 5.5C7.31928 5.5 6.19998 6.6193 6.19998 8C6.19998 9.3807 7.31928 10.5 8.69998 10.5Z" /></svg>
						<Typography variant='body3'>{locationName}</Typography>
					</Box>
				</Box>
        <Box sx={styles.corners}>
          <Box sx={styles.corner}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60" fill="none"><path fill={theme.palette.background.default} d="M60 0H0V60C0 26.8628 26.8628 0 60 0Z" /></svg>
          </Box>
          <Box sx={styles.corner}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60" fill="none"><path fill={theme.palette.background.default} d="M0 0H60V60C60 26.8628 33.1372 0 0 0Z" /></svg>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.body}>
        {markup}
      </Box>
    </Box>
	)
}

export default MediaAllGroup