import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { writeBatch } from 'firebase/firestore'
import { Box, alpha } from '@mui/material'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import SetupSidebar from './SetupSidebar'
import SetupHeader from './SetupHeader'
import SetupUsername from './SetupUsername'
import SetupProfile from './SetupProfile'
import SetupSports from './SetupSports'
import topo from '../../assets/images/topo-space.svg'

const steps = [
  { label: 'Choose a username', },
  { label: 'Profile information', },
  { label: 'Select sports', },
]

const styles = {
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    padding: '64px 24px',
    background: `linear-gradient(
      ${alpha(theme.palette.background.paper, 0.972)},
      ${alpha(theme.palette.background.paper, 0.972)}), url(${topo}), ${theme.palette.background.paper}`,
    backgroundSize: '60%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '85% 50%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: { backgroundSize: '80%', backgroundPosition: '100% 50%', },
    [theme.breakpoints.down('sm')]: { backgroundSize: '90%', padding: '40px 24px 64px', },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 880,
    padding: 5,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 4,
      paddingLeft: 2.5,
      paddingRight: 2.5,
      paddingBottom: 4,
    },
  },
}

const Setup = () => {
  const user = useSelector(state => state.rootReducer.user.data)
  const location = useLocation()
  const [values, setValues] = useState({
    username: user?.username || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    city: user?.city || '',
    state: user?.state || '',
    zip: user?.zip || '',
    dob: user?.dob || '',
    phone: user?.phone || '',
    sports: user?.sports || [],
  })
  const [activeStep, setActiveStep] = useState(0)
  const batch = writeBatch(db)

  if (!user || !!user?.username) return <Navigate to='/' state={{ from: location }} />

  let markup

  switch (activeStep) {
    case 0:
      markup = <SetupUsername values={values} setValues={setValues} setActiveStep={setActiveStep} batch={batch} />
      break
    case 1:
      markup = <SetupProfile values={values} setValues={setValues} setActiveStep={setActiveStep} />
      break
    case 2:
      markup = <SetupSports values={values} setValues={setValues} setActiveStep={setActiveStep} batch={batch} />
      break
    default:
      markup = <>Loading</>
  }

  return (
    <Box sx={styles.wrapper}>
      <SetupSidebar steps={steps} activeStep={activeStep} />
      <Box sx={styles.main}>
        <SetupHeader />
        <Box sx={styles.card}>
          {markup}
        </Box>
      </Box>
    </Box>
  )
}

export default Setup