import { useRef, useState } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { auth } from '../../firebase/initialize'
import { decode } from '../../utilities/errorDecoding'
import Snackbar from '../_common/Snackbar'
import { styles } from './formStyles'

const LandingReset = ({ setMode }) => {
  const emailRef = useRef()
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  })

  const handleReset = async (e) => {
    e.preventDefault()
    setLoading(true)
    
    try {
      await sendPasswordResetEmail(auth, emailRef.current.value)
      setSnackbar({ open: true, type: 'success', message: 'A password reset email was sent to the address provided.' })
      setLoading(false)
    } catch(error) {
      console.error(error)
      setSnackbar({ open: true, type: 'error', message: decode(error.code) })
      setLoading(false)
    }
  }

  return (
    <Box sx={styles.formWrapper}>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
      <Typography variant='body3' sx={styles.message}>Enter your email address to get a password reset link.</Typography>
      <Box sx={{ ...styles.form, maxWidth: 480, }}>
        <input className='form__textfield' type='email' ref={emailRef} placeholder='Email address' required />
        <Box sx={styles.submit}>
          <Button variant='contained' color='primary' size='large' onClick={handleReset} disabled={loading} disableRipple>
            {loading ? <CircularProgress /> : 'Reset password'}
          </Button>
        </Box>
      </Box>
      <Typography variant='body3' sx={styles.switchLink} onClick={() => setMode('login')}>Remembered your password?</Typography>
    </Box>
  )
}

export default LandingReset