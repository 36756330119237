import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import Section from '../_common/Section'
import HomeFavoritesEmpty from './HomeFavoritesEmpty'
import HomeFavoritesItem from './HomeFavoritesItem'

const HomeFavorites = () => {
	const media = useSelector(state => state.rootReducer.media.data)
	const favorites = media?.filter(mediaItem => mediaItem?.favorite === true)
	const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
		},
		list: {
			display: 'flex',
			gap: 1.5,
			marginRight: -3,
			paddingRight: 3,
			overflowX: 'auto',
			'&::-webkit-scrollbar': { display: 'none', },
			[theme.breakpoints.down('md')]: {
				gap: 1,
				marginLeft: -2.5,
				marginRight: -2.5,
				paddingLeft: 2.5,
				paddingRight: 2.5,
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: -2,
				marginRight: -2,
				paddingLeft: 2,
				paddingRight: 2,
			},
		},
		corners: {
			position: 'absolute',
			top: -0.5,
			left: 0,
			bottom: -0.5,
			zIndex: 3,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			[theme.breakpoints.down('md')]: { display: 'none', },
		},
		corner: {
			height: 16,
			aspectRatio: '1 / 1',
		},
	}

	// Button markup
	const button = (
		<Button variant='text' color='secondary' size='small' component={Link} to='/media?tab=favorites' disableRipple>
			<Typography variant='button1' children='See all'/>
			<svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewBox="0 0 448 512"><path fill={theme.palette.text.primary} d="M429.8 273l17-17-17-17L276.2 85.4l-17-17-33.9 33.9 17 17L354.9 232 24 232 0 232l0 48 24 0 330.8 0L242.2 392.6l-17 17 33.9 33.9 17-17L429.8 273z"/></svg>
		</Button>
	)

	// Corners markup
	const corners = (
		<Box sx={styles.corners}>
			<Box sx={styles.corner}>
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60"><path fill={theme.palette.background.default} d="M60 0H0V60C0 26.8628 26.8628 0 60 0Z"/></svg>
			</Box>
			<Box sx={{ ...styles.corner, transform: 'rotate(180deg)', }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60"><path fill={theme.palette.background.default} d="M0 0H60V60C60 26.8628 33.1372 0 0 0Z"/></svg>
			</Box>
		</Box>
	)
	
	// Create list of favorite items
	const favoritesItems = favorites
		?.sort((a, b) => (b.datetime - a.datetime))
		.slice(0, 8)
		.map((mediaItem, index) => <HomeFavoritesItem mediaItem={mediaItem} key={index} />)


	// Populated markup
	const favoritesListMarkup = (
		<>
			{corners}
			<Box sx={styles.list}>{favoritesItems}</Box>
		</>
	)

	return (
		<Box sx={styles.wrapper}>
			<Section
				headHeight={width < 992 ? 56 : 70}
				heading={<Typography variant={width > 992 ? 'h2' : 'h3'} children='Favorite Shots' />}
				actions={button}
				children={!favoritesItems?.length ? <HomeFavoritesEmpty /> : favoritesListMarkup}
			/>
		</Box>
	)
}
 
export default HomeFavorites